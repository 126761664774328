import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Grid,
  Menu,
  MenuProps,
  autocompleteClasses,
} from '@mui/material';
import { Box, Theme, styled } from '@mui/system';
import 'react-tabulator/lib/styles.css'; // Default theme
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css'; // Bootstrap theme
import { ReactTabulator } from 'react-tabulator';
import TitleText from '../../components/formlib/TitleText';
import './reports.css';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { formatName } from '../../utils/nameUtils';
import EmptyScreen from '../../components/shared/EmptyScreen';
import {
  CancelationReportAppointments,
  CancelationReportJSON,
  getCancelationReport,
} from '../../services/reportsApi/report';
import AppPagination from '../../components/shared/AppPagination';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { Client, OptionType, ResponseType } from '../../utils/type';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import DisplayFilter, { FilterBy } from '../../components/shared/DisplayFilter';
import { Employee } from '../../services/configApi/employees/employeeServices';
import { Formik, FormikProps } from 'formik';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as Filtericon } from '../../assets/images/FilterIconsvg.svg';
import { right } from '@popperjs/core';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import MultiSelectAutocompleteSearch, {
  MultiSelectOptioinType,
} from '../../components/formlib/MultiSelectAutocompleteSearch';
import * as yup from 'yup';
import { appointmentTypesFilter } from '../scheduler/schedulerUtils';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import { getAllClients } from '../../services/configApi/Clients';
import { getAllEmployeeMinimalList } from '../../services/ifspClient/IfspApi';
import { encodeURI } from 'js-base64';

// Define table columns
const columns = [
  {
    title: 'Appointment Type',
    field: 'appointmentType',
    minWidth: 200,
    maxWidth: 200,
    tooltip: true,
    cssClass: 'appointmentTypeStyle',
  },
  {
    title: 'Attendee',
    field: 'attendee',
    formatter: 'html',
    minWidth: 200,
    maxWidth: 200,
    tooltip: true,
  },
  {
    title: 'Client Name',
    field: 'clientName',
    minWidth: 200,
    maxWidth: 200,
    tooltip: true,
  },
  {
    title: 'Status',
    field: 'progressNotesStatus',
    formatter: 'html',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    title: 'Canceled Type',
    field: 'canceledType',
    minWidth: 160,
    maxWidth: 160,
    tooltip: true,
  },
  {
    title: 'Canceled Reason',
    field: 'canceledReason',
    minWidth: 100,
    maxWidth: 200,
    widthGrow: 2,
    tooltip: true,
    clipContents: true,
  },
];

interface RawData {
  clientName: string;
  date: string;
  appointmentType: string;
  attendee: string;
  canceledType: string;
  canceledReason: string;
  progressNotesStatus: string;
}

const CustomMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }: { theme: Theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    maxWidth: 490,
    padding: '20px 25px',
    boxShadow: '10px 10px 50px 0px rgba(0, 71, 66, 0.3)',
  },
}));

export type CancelationReportFilterType = {
  startDate: Dayjs | null | string;
  endDate: Dayjs | null | string;
  appointmentType: [];
  canceledType: string | null;
  attendeeIds: Employee[];
  clientIds: Client[];
};

// need to work
const validationSchema = yup.object().shape({
  startDate: yup.string().nullable(),
  endDate: yup.string().nullable(),
  appointmentType: yup.array().optional(),
  // canceledType: yup.string().optional(),
  attendeeIds: yup.array().optional(),
  clientIds: yup.array().optional(),
});

const CancelationReport = () => {
  const intl = useIntl();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [reportData, setReportData] = useState<CancelationReportJSON>({
    totalCount: 0,
    cancellationReportAppointments: [],
  });
  const [page, setPage] = useState<number>(0);
  const itemPerPage = 10;
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [filterBy, setFilterBy] = React.useState<FilterBy[]>([]);
  const [filterPlainData, setFilterPlainData] =
    React.useState<CancelationReportFilterType>({
      startDate: null,
      endDate: null,
      appointmentType: [],
      canceledType: null,
      clientIds: [],
      attendeeIds: [],
    });

  const formRef = React.useRef<FormikProps<CancelationReportFilterType>>(null);
  const [filterData, setFilterData] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const openit = Boolean(anchorEl);

  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    getReportList();
  }, []);

  useEffect(() => {
    getReportList();
  }, [page, filterData]);

  const getClients = () => {
    getAllClients(false)
      .then((response) => {
        setClients(response);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
          setSuccessOrError('error');
        } else {
          const responseError = error as ApiMessage;
          setToastrId('failedToGetEmployee');
          setToastrDefaultMessage(responseError.message);
          setSuccessOrError('error');
        }
      });
  };

  const getEmployees = () => {
    getAllEmployeeMinimalList()
      .then((data) => {
        let employeelist: Employee[] = [];
        employeelist = data.map((employee) => {
          return createEmployeeObj(
            employee.id,
            employee.name,
            employee.email,
            employee.firstName,
            employee.lastName,
            employee.disciplineType,
            employee.phone,
          );
        });
        employeelist.unshift(
          createEmployeeObj(
            'Thom Connect',
            'Thom Connect',
            '',
            'Thom Connect',
            '',
            '',
            '',
          ),
        );

        setEmployeeList(employeelist);

        getClients();
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
          setSuccessOrError('error');
        } else {
          setToastrId('failedToGetEmployee');
          setToastrDefaultMessage('Failed to get employee data');
          setSuccessOrError('error');
        }
      });
  };

  const createEmployeeObj = (
    id: string,
    name: string | null,
    email: string | null,
    firstName: string | null,
    lastName: string | null,
    disciplineType: string | null,
    phone: string | null,
  ) => {
    return {
      id: id,
      name: name,
      firstName: firstName,
      lastName: lastName,
      email: email,
      disciplineType: disciplineType,

      nameSuffix: '',
      clientId: '',
      kcUsername: '',
      middleName: '',
      preferredName: '',
      phone1: phone,
      phone2: '',
      workingHoursPerWeek: 0,
      anticipatedHoursPerWeek: 0,
      allowAnticipatedHoursEdit: null,
      employeeRole: '',
      reportingTo: '',
      employeeType: '',
      startDate: '',
      endDate: '',
      status: '',
      primaryAddress1: '',
      primaryAddress2: '',
      primaryZipcode: '',
      primaryCity: '',
      primaryState: '',
      secondaryAddressRequired: false,
      secondaryAddress1: '',
      secondaryAddress2: '',
      secondaryZipcode: '',
      secondaryCity: '',
      secondaryState: '',
      emergencyFirstName: '',
      emergencyLastName: '',
      emergencyPhone: '',
      emergencyEmail: '',
      emergencyAddress1: '',
      emergencyAddress2: '',
      emergencyZipcode: '',
      emergencyCity: '',
      emergencyState: '',
      programs: [],
      modifiedOn: '',
      allowDelete: null,
      employeeCredentials: null,
      signature: '',
      pin: '',
      phoneNumberExtension: '',
      phoneNumberExtension2: '',
      defaultProgram: '',
      superProgramId: '',
      group: {
        id: '',
        groupName: '',
      },
    };
  };

  const clearForm = () => {
    setPage(0);
    formRef.current?.resetForm();
    setFilterPlainData({
      startDate: null,
      endDate: null,
      appointmentType: [],
      canceledType: null,
      clientIds: [],
      attendeeIds: [],
    });
    setFilterBy([]);
    setFilterData('');
    setAnchorEl(null);
  };

  const handlefilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlefilterClose = () => {
    setAnchorEl(null);
  };

  const applyFilter = (values: CancelationReportFilterType) => {
    const filterDataBy: FilterBy[] = [];
    setFilterPlainData(values);
    if (
      values.endDate != null &&
      values.endDate !== '' &&
      values.startDate != null &&
      values.startDate !== ''
    ) {
      filterDataBy.push({
        key: 'startDateText',
        value: values.startDate + ' - ' + values.endDate,
      });
    }
    if (
      values.endDate != null &&
      values.endDate !== '' &&
      values.startDate != null &&
      values.startDate !== ''
    ) {
      filterDataBy.push({
        key: 'endDateText',
        value: values.startDate + ' - ' + values.endDate,
      });
    }
    if (values.clientIds.length > 0) {
      filterDataBy.push({
        key: 'TaskManager.clientfilter',
        value: values.clientIds
          .map((client) =>
            formatName(
              client.firstName,
              client.middleName,
              client.lastName,
              client.suffix,
            ),
          )
          .join(', '),
      });
    }
    if (values.attendeeIds.length > 0) {
      filterDataBy.push({
        key: 'ViewSchedulerEvent.clinicians',
        value: values.attendeeIds
          .map((origin) => origin.firstName + ' ' + origin.lastName)
          .join(', '),
      });
    }

    if (values.appointmentType.length > 0) {
      filterDataBy.push({
        key: 'AddNewSchedulerEvent.appointmentType',
        value: values.appointmentType
          .map((type: OptionType) => type.label)
          .join(', '),
      });
    }
    setFilterBy(filterDataBy);

    if (filterDataBy.length <= 2) {
      if (filterDataBy.some((item) => item.value === 'false')) {
        clearForm();
        setFilterBy([]);
        configureFilterAndCallAPI(null);
      } else {
        configureFilterAndCallAPI(values);
      }
    } else {
      configureFilterAndCallAPI(values);
    }
  };

  const getReportList = async () => {
    toggleLoader(true);
    await getCancelationReport(page, itemPerPage, filterData)
      .then((response) => {
        setReportData(response);
        if (clients.length === 0 || employeeList.length === 0) {
          getEmployees();
        } else {
          toggleLoader(false);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('cancelationReportGetFailureMessage');
          setToastrDefaultMessage('Failed to get cancelation report details');
        }
      });
  };

  const configureFilterAndCallAPI = (
    values: CancelationReportFilterType | null,
  ) => {
    let filterString: string = '';

    if (values?.endDate && values?.startDate) {
      filterString = filterString
        .concat('filterByAppointmentDate>=')
        .concat(values.startDate.toString())
        .concat('&')
        .concat('filterByAppointmentDate<=')
        .concat(values.endDate.toString());
    }
    if (values?.appointmentType) {
      if (filterString !== '') {
        filterString = filterString.concat('&');
      }
      const appointmentTypeFltr = values.appointmentType
        .map((type: OptionType) => type.id)
        .join(',');
      filterString = filterString
        .concat('filterByAppointmentTypes=')
        .concat(appointmentTypeFltr);
    }
    if (values?.clientIds) {
      if (filterString !== '') {
        filterString = filterString.concat('&');
      }
      const clientFltr = values.clientIds
        .map((client) => client.clientId)
        .join(',');
      filterString = filterString
        .concat('filterByClientIds=')
        .concat(clientFltr);
    }
    if (values?.attendeeIds) {
      if (filterString !== '') {
        filterString = filterString.concat('&');
      }
      const attendeeFltr = values.attendeeIds
        .map((attendee) => attendee.id)
        .join(',');
      filterString = filterString
        .concat('filterByAttendeeIds=')
        .concat(attendeeFltr);
    }

    const encodedFltr = encodeURI(filterString);
    setFilterData(encodedFltr);
  };

  // Memoize rawData to avoid recalculating it on every render
  const rawData: RawData[] = useMemo(() => {
    if (reportData.cancellationReportAppointments.length === 0) return [];
    return reportData.cancellationReportAppointments.map(
      (appointment: CancelationReportAppointments) => ({
        clientName: formatName(
          appointment.clientFirstName,
          appointment.clientMiddleName,
          appointment.clientLastName,
          appointment.clientSuffix,
        ),
        date: new Date(appointment.appointmentStartTime).toLocaleDateString(),
        appointmentType: appointment.appointmentType,
        attendee: appointment.attendeeFirstName
          ? `${appointment.attendeeFirstName} ${
              appointment.attendeeLastName || ''
            }`.trim()
          : '-',
        canceledType: appointment.cancelReason?.split(' - ')[0] || '-',
        canceledReason: appointment.cancelReason?.split(' - ')[1] || '-',
        progressNotesStatus: appointment.progressNotesStatus.includes(
          'Canceled',
        )
          ? "<span style='color: red;'>Canceled</span>"
          : 'Pending',
      }),
    );
  }, [reportData.cancellationReportAppointments]);

  // Memoize tableData derived from rawData
  const tableData: RawData[] = useMemo(() => {
    return rawData.map((item) => ({
      ...item,
      appointmentType: item.appointmentType
        ? intl.formatMessage({
            id: item.appointmentType,
            defaultMessage: item.appointmentType,
          })
        : '-',
    }));
  }, [rawData]);

  const options = {
    layout: 'fitDataFill', // Ensures columns fit within the available space
    responsiveLayout: 'collapse', // Enables responsive layout
    autoResize: true,
    movableColumns: false,
    resizableColumns: false,
    headerSort: false,
    groupBy: 'date',
    columnDefaults: {
      resizable: false, // Prevent resizing of columns
      tooltip: true, // Show full content on hover
      widthGrow: 1, // Allow columns to grow proportionally
      clipContents: true, // Clip content that overflows
    },
    groupHeader: (value: string, count: number) =>
      `Date: ${dayjs(value).format('MM/DD/YYYY')} (${count} visit${
        count > 1 ? 's' : ''
      })`,
  };

  function compareObjectsDeeply(
    obj1: CancelationReportFilterType,
    obj2: CancelationReportFilterType,
  ): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  const validateForm = (value: CancelationReportFilterType) => {
    const errors: Partial<CancelationReportFilterType> = {};
    if (
      value.startDate &&
      value.endDate &&
      dayjs(value.startDate).isAfter(dayjs(value.endDate))
    ) {
      errors.endDate = 'endDateGreaterThanStartDate';
    }
    return errors;
  };

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          successOrError={successOrError}
        />
      )}
      <Box component="main">
        <Box component="section">
          <Box component="div" className="rowContainer">
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Box className="flex__ align__items__center">
                  <TitleText
                    labelid="cancelationReportTitle"
                    defaultlabel="Cancelation Report"
                  />
                </Box>
              </Grid>

              <Grid item xs={6} lg={6} sx={{ textAlign: 'right' }}>
                <Box
                  sx={{
                    flexGrow: 0,
                  }}
                >
                  <Button
                    className="btn-primary btn-filter"
                    variant="outlined"
                    onClick={handlefilter}
                    endIcon={<KeyboardArrowDownIcon />}
                    startIcon={<Filtericon />}
                  >
                    <FormattedMessage id="FiterText" defaultMessage="Filter" />
                  </Button>
                  <MemorizedCustomMenu
                    id="demo-customized-menu"
                    anchorEl={anchorEl}
                    open={openit}
                    onClose={handlefilterClose}
                  >
                    <Formik
                      innerRef={formRef}
                      initialValues={filterPlainData}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        setAnchorEl(null);
                        applyFilter(values);
                      }}
                      validate={validateForm}
                    >
                      {({
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                      }) => (
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="startDate"
                              value={
                                values.startDate !== null
                                  ? dayjs(values.startDate)
                                  : null
                              }
                              labelid="startDateText"
                              defaultlabelid="Start Date"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('startDate', null);
                                } else {
                                  setFieldValue('startDate', formattedDate);
                                }
                                setFieldValue('endDate', null);
                              }}
                            />
                            {errors.startDate && touched.startDate && (
                              <MediumTypography
                                labelid={errors.startDate}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="endDate"
                              value={
                                values.endDate !== null
                                  ? dayjs(values.endDate)
                                  : null
                              }
                              labelid="endDateText"
                              defaultlabelid="End Date"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('endDate', null);
                                } else {
                                  setFieldValue('endDate', formattedDate);
                                }
                              }}
                            />
                            {errors.endDate && touched.endDate && (
                              <MediumTypography
                                labelid={errors.endDate}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} className="multisearchHgt">
                            <MultipleAutocompleteSearch
                              labelId="scheduler.filter.appointmentsType"
                              defaultId="Select Appointments Type"
                              data={appointmentTypesFilter.filter(
                                (item) =>
                                  !values.appointmentType.some(
                                    (selectedItem) => selectedItem === item.id,
                                  ),
                              )}
                              type="string"
                              onSelectItem={(value: OptionType[] | null) => {
                                if (value) {
                                  setFieldValue('appointmentType', value);
                                } else {
                                  setFieldValue('appointmentType', []);
                                }
                              }}
                              disableLabel={true}
                              optionHintId={'No Appointment Type Found'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                  marginTop: '2px',
                                },
                                backgroundColor: '#fff',
                              }}
                              value={values.appointmentType}
                            />
                          </Grid>

                          <Grid item xs={12} className="multisearchHgt">
                            <MultiSelectAutocompleteSearch
                              labelId="formBuilder.SelectClient"
                              defaultId="Select Client"
                              onlyName={true}
                              value={values.clientIds}
                              onSelectItem={(
                                value: MultiSelectOptioinType[],
                              ) => {
                                if (value.length > 0) {
                                  setFieldValue('clientIds', value);
                                } else {
                                  setFieldValue('clientIds', []);
                                }
                              }}
                              data={clients}
                              optionHintId={
                                'AddNewSchedulerEvent.noClientsFoundInOptions'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} className="multisearchHgt">
                            <MultiSelectAutocompleteSearch
                              labelId="selectAttendeesText"
                              defaultId="Select Attendees"
                              onlyName={true}
                              onSelectItem={(
                                value: MultiSelectOptioinType[],
                              ) => {
                                if (value) {
                                  if (employeeList.length > 0) {
                                    setFieldValue('attendeeIds', value);
                                  } else {
                                    setFieldValue('attendeeIds', []);
                                  }
                                }
                              }}
                              value={
                                values.attendeeIds ? values.attendeeIds : []
                              }
                              highlightSelectedValues={[]}
                              highlightSelectedValuesTooltipLabelId=""
                              data={employeeList}
                              hideOption={null}
                              optionHintId={
                                'AddNewSchedulerEvent.noCliniciansFoundInOptions'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} textAlign={right} mt={1.5}>
                            <Box
                              sx={{
                                justifyContent: 'right',
                                display: 'flex',
                              }}
                            >
                              <Box className="mr-md">
                                <ButtonComponent
                                  className="btn-primary btn-cancel btn_width_90px"
                                  variantType="contained"
                                  labelId="Dashboard.filtercancel"
                                  defaultLabelId="Cancel"
                                  onClick={() => {
                                    setAnchorEl(null);
                                  }}
                                  disabled={false}
                                />
                              </Box>
                              <Box>
                                <ButtonComponent
                                  className="btn-primary btn-submit btn_width_90px"
                                  variantType="contained"
                                  type="submit"
                                  labelId="Dashboard.filtersubmit"
                                  defaultLabelId="Apply"
                                  onClick={() => {
                                    if (
                                      compareObjectsDeeply(
                                        filterPlainData,
                                        values,
                                      )
                                    ) {
                                      return;
                                    }

                                    handleSubmit();
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Formik>
                  </MemorizedCustomMenu>
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                {filterBy.length > 0 && (
                  <Box sx={{ marginBottom: '10px' }}>
                    <DisplayFilter
                      filteredData={filterBy}
                      handleCloseClick={(
                        index: number,
                        key: string | undefined,
                      ) => {
                        if (filterBy.length < 2) {
                          clearForm();
                        } else {
                          if (key === 'scheduler.filter.appointmentsType') {
                            const updatedFilterPlainData: CancelationReportFilterType =
                              {
                                ...filterPlainData,
                                appointmentType: [],
                              };
                            applyFilter(updatedFilterPlainData);
                          } else if (key === 'formBuilder.SelectClient') {
                            const updatedFilterPlainData: CancelationReportFilterType =
                              {
                                ...filterPlainData,
                                clientIds: [],
                              };
                            applyFilter(updatedFilterPlainData);
                          } else if (key === 'selectAttendeesText') {
                            const updatedFilterPlainData: CancelationReportFilterType =
                              {
                                ...filterPlainData,
                                attendeeIds: [],
                              };
                            applyFilter(updatedFilterPlainData);
                          } else if (key === 'startDateText') {
                            const updatedFilterPlainData: CancelationReportFilterType =
                              {
                                ...filterPlainData,
                                startDate: null,
                                endDate: null,
                              };
                            applyFilter(updatedFilterPlainData);
                          }
                        }
                      }}
                      handleClearClick={() => {
                        clearForm();
                      }}
                      sxProps={{ marginTop: '0px' }}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          {reportData.cancellationReportAppointments.length === 0 && (
            <Box className="pb-md">
              <EmptyScreen
                titleLabelId="noCancelationReportTitle"
                defaultTitleText="No Cancelation Report yet."
                showButton={false}
              />
            </Box>
          )}
          {reportData.cancellationReportAppointments.length > 0 && (
            <>
              <Box
                style={{
                  display: 'grid',
                }}
              >
                <ReactTabulator
                  columns={columns}
                  data={tableData}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                className="pb-md"
              >
                <AppPagination
                  pageNumber={page}
                  paginationCount={reportData.totalCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== page) {
                      setPage(newPage - 1);
                    }
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CancelationReport;
