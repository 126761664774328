import {
  AlertColor,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import Textinput from '../../components/formlib/Textinput';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { ReactComponent as MessageIcon } from '../../assets/images/messageIcon.svg';
import { ReactComponent as MessageActive } from '../../assets/images/messagegreen.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import { ReactComponent as AttachmentGray } from '../../assets/images/attachmentGrey.svg';
import { ReactComponent as AttachmentGreen } from '../../assets/images/attachmentGreen.svg';
import {
  TravelExpense,
  addAttachmentTravelExpenses,
  deleteAttachmentTravelExpensesById,
} from '../../services/travelExpenses/TravelExpensesApi';
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { OptionType } from '../../utils/type';
import ActivityNoteModal from '../../components/formlib/modal/ActivityNoteModal';
import { v4 as uuidv4 } from 'uuid';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import {
  PREVIEW_DOCUMENT_EXTENSIONS,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import { extractFileExtension } from '../../utils/fileUtils';
import FilePreviewDialog from '../../components/formlib/modal/FilePreviewDialog';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ReactComponent as CloseIcon } from '../../assets/images/x.svg';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

interface ExpenseData {
  data: TravelExpense;
  index: number;
  onDeleteExpense?: (data: TravelExpense, index: number) => void;
  handleDataChange: (data: TravelExpense, i: number) => void;
  mileageRate: string;
  status: string;
  month: string;
  year: string;
  review?: boolean;
}

const ExpenseTableRow: FC<ExpenseData> = ({
  data,
  index,
  onDeleteExpense,
  handleDataChange,
  mileageRate,
  status,
  month,
  year,
  review,
}) => {
  const [openNoteModel, setOpenNoteModel] = useState<boolean>(false);
  const [openAttachmentModal, setOpenAttachmentModal] =
    useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [filesSelected, setFilesSelected] = useState<
    { id: string; file: File }[]
  >([]);
  const [showPreviewModel, setShowPreviewModel] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<Dayjs>();
  const [maxDate, setMaxDate] = useState<Dayjs>();
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(
    null,
  );
  const [expenseType, setExpenseType] = useState<OptionType[]>([
    {
      id: '',
      label: '',
    },
  ]);
  const { loader, toggleLoader } = useContext(
    LoaderContext,
  ) as LoaderContextType;
  const { lookups } = useContext(LookUpContext) as LookUpContextType;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const browseFiles = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles =
      event.target.files !== null ? Array.from(event.target.files) : [];
    event.target.value = '';

    selectedFiles.forEach((selectedFile) => {
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/jpg',
      ];
      if (
        allowedTypes.includes(selectedFile.type) &&
        selectedFile.size <= 26214400 &&
        selectedFile.size !== 0
      ) {
        const tempId = uuidv4();
        filesSelected.push({ id: tempId, file: selectedFile });
        setFilesSelected(filesSelected);

        handleDataChange(
          {
            ...data,
            notModified: false,
            attachments: [
              ...data.attachments,
              {
                url: URL.createObjectURL(selectedFile),
                name: selectedFile.name,
                id: tempId,
                attachmentId: '',
              },
            ],
          },
          index,
        );
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('fileValidate');
        setToastrDefaultMessage(
          'Please select valid PDF, JPEG, or PNG file(s) with a size of up to 25MB.',
        );
      }
    });
  };

  const handleDelete = () => {
    if (onDeleteExpense) {
      onDeleteExpense(data, index);
    }
  };
  const handleCloseNoteModal = () => {
    setOpenNoteModel(false);
    if (data.note === '') {
      handleDataChange(
        {
          ...data,
          notModified: false,
          note: '',
        },
        index,
      );
    }
  };
  const handleOpenNoteModal = () => {
    setOpenNoteModel(true);
  };

  const handleCloseAttachmentModal = () => {
    setOpenAttachmentModal(false);
  };
  const handleOpenAttachmentModal = () => {
    setOpenAttachmentModal(true);
  };

  const handlePreviewClick = (i: number) => {
    setSelectedFileIndex(i);
    setShowPreviewModel(true);
  };

  const handleClosePreviewModel = () => {
    setShowPreviewModel(false);
    setSelectedFileIndex(null);
  };

  const getDisabledState = () => {
    let disable = true;
    data.attachments.forEach((item) => {
      if (item.attachmentId === '') {
        disable = false;
      }
    });
    return disable;
  };

  const handleSaveAttachments = () => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (employeeId === null || filesSelected.length === 0) {
      return;
    }

    toggleLoader(true);
    addAttachmentTravelExpenses(
      employeeId,
      filesSelected.map((item) => item.file),
    )
      .then((response) => {
        toggleLoader(false);
        const newAttachments = response.map((item) => ({
          ...item,
          name: item.name.replace('travelExpense/', ''),
        }));
        const updatedAttachments = [
          ...data.attachments.filter((item) => !!item.attachmentId),
          ...newAttachments,
        ];
        handleDataChange(
          {
            ...data,
            notModified: false,
            attachments: updatedAttachments,
          },
          index,
        );
        setOpen(true);
        setFilesSelected([]);

        setToastrVariable('success');
        setToastrId('travelAttachments.success');
        setToastrDefaultMessage(
          'Travel Expense Attachments added successfully',
        );
        setOpenAttachmentModal(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setOpenAttachmentModal(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelExpensesAttachments.error');
          setToastrDefaultMessage('Failed to add travel expense attachments');
        }
      });
  };

  const handleRemoveFile = (id: string) => {
    const attachment = data.attachments.find((file) => file.id === id);
    if (attachment === undefined) {
      return;
    }
    toggleLoader(true);
    if (attachment.attachmentId) {
      deleteAttachmentTravelExpensesById(attachment.attachmentId)
        .then(() => {
          toggleLoader(false);
          handleDataChange(
            {
              ...data,
              notModified: false,
              attachments: data.attachments.filter(
                (attach) => attach.id !== id,
              ),
            },
            index,
          );
          setOpen(true);
          setToastrVariable('success');
          setToastrId('travelExpensesdelete.success');
          setToastrDefaultMessage(
            'Travel Expense attachments deleted successfully',
          );
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('travelExpensesdelete.error');
            setToastrDefaultMessage('Failed to delete attachment');
          }
        });
    } else {
      toggleLoader(false);

      setFilesSelected(filesSelected.filter((file) => file.id !== id));
      handleDataChange(
        {
          ...data,
          notModified: false,
          attachments: data.attachments.filter((attach) => attach.id !== id),
        },
        index,
      );
    }
  };

  useEffect(() => {
    if (lookups === null) {
      return;
    }
    setExpenseType(() =>
      lookups.travelTypes.map((item) => {
        return {
          id: item.type,
          label: item.description,
        };
      }),
    );
  }, [lookups]);
  useEffect(() => {
    if (month !== undefined && year !== undefined) {
      if (month !== undefined && year !== undefined) {
        // const startDate = new Date(Number(year), Number(month) - 1, 1);
        // const endDate = new Date(Number(year), Number(month), 0);

        const startDate = dayjs()
          .year(Number(year))
          .month(Number(month) - 1)
          .startOf('month');
        const endDate = dayjs()
          .year(Number(year))
          .month(Number(month) - 1)
          .endOf('month');

        setMinDate(startDate);
        setMaxDate(endDate);
      }
    }
  }, [month, year]);

  return (
    <>
      {openNoteModel && (
        <ActivityNoteModal
          hideUpdateButton={review}
          closeOnBackdropClick={false}
          open={openNoteModel}
          note={data.note ?? ''}
          handleClose={handleCloseNoteModal}
          maxLength={300}
          updateNote={(value: string) => {
            handleDataChange(
              {
                ...data,
                notModified: false,
                note: value,
              },
              index,
            );
            setOpenNoteModel(false);
          }}
        />
      )}
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {showPreviewModel && selectedFileIndex !== null && (
        <FilePreviewDialog
          url={data.attachments[selectedFileIndex].url}
          fileName={data.attachments[selectedFileIndex].name}
          onClose={handleClosePreviewModel}
        />
      )}
      {openAttachmentModal && (
        <Dialog open={openAttachmentModal} fullWidth maxWidth={'sm'}>
          <DialogTitle>
            <Box className="flex__ ">
              <MediumTypography
                labelid={'attachTitle'}
                defaultlabel="Attachment(s) -"
                sxProps={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
                className="mt-md"
              />
              <MediumTypography
                label={
                  data.expenseDate !== null ? (data.expenseDate as string) : ''
                }
                className="pl-xs mt-md"
                sxProps={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container direction={'row'}>
              {data.attachments.map((file, i) => (
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <Grid
                    item
                    key={i.toString()}
                    width={'160px'}
                    height={'160px'}
                    className="mb-md"
                    sx={{
                      border: '1px dashed #97A6A5',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      marginRight: '16px',
                    }}
                  >
                    {file.name !== null &&
                    (file.name.toLowerCase().includes('.jpg') ||
                      file.name.toLowerCase().includes('.jpeg') ||
                      file.name.toLowerCase().includes('.png')) ? (
                      <img
                        src={file.url}
                        width={'160px'}
                        height={'160px'}
                        alt={file.name}
                        onClick={() => {
                          handlePreviewClick(i);
                        }}
                      />
                    ) : (
                      <Tooltip title={file.name}>
                        <Chip
                          label={file.name}
                          clickable
                          sx={{
                            borderRadius: '4px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            height: '26px',
                            display: 'block',
                            position: 'relative',
                            textDecorationLine: 'underline',
                          }}
                          onClick={() => {
                            if (
                              PREVIEW_DOCUMENT_EXTENSIONS.includes(
                                extractFileExtension(file.name).toLowerCase(),
                              )
                            ) {
                              handlePreviewClick(i);
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                  {!review && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '2px',
                        right: '20px',

                        padding: '2px',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleRemoveFile(file.id);
                      }}
                    >
                      <CloseIcon />
                    </Box>
                  )}
                </Box>
              ))}
              {!review && (
                <Grid
                  item
                  width={'160px'}
                  height={'160px'}
                  className="mb-md"
                  sx={{
                    border: '1px dashed #97A6A5',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    marginRight: '16px',
                    opacity: data.attachments.length === 5 ? 0.5 : 1,
                    pointerEvents:
                      data.attachments.length === 5 ? 'none' : 'auto',
                  }}
                  onClick={browseFiles}
                >
                  <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    accept=".pdf,.jpg,.jpeg,.png"
                    onChange={handleFileChange}
                  />

                  <MediumTypography
                    labelid="Add Attachment"
                    defaultlabel="Add Attachment"
                  />
                </Grid>
              )}
            </Grid>

            <Box className="mt-lg flex__ justifyContent-FlexEnd">
              <ButtonComponent
                className="btn-primary btn-cancel"
                labelId={'cancelText'}
                defaultLabelId="Cancel"
                variantType="outlined"
                onClick={() => {
                  handleCloseAttachmentModal();
                  const attachmentsWithId = data.attachments.filter(
                    (attachment) => attachment.id !== '',
                  );
                  setFilesSelected([]);
                  handleDataChange(
                    {
                      ...data,
                      notModified: false,
                      attachments: attachmentsWithId,
                    },
                    index,
                  );
                }}
              />
              {!review && (
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  labelId={'Save'}
                  defaultLabelId="Save"
                  loading={loader}
                  onClick={() => {
                    handleSaveAttachments();
                  }}
                  disabled={getDisabledState()}
                />
              )}
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <TableRow>
        <TableCell>
          <DatePickerComponent
            className=" addTravelExpenseDatePicker "
            labelid=""
            disableFuture={false}
            defaultlabelid=""
            slots={{
              leftArrowIcon: () => null,
              rightArrowIcon: () => null,
            }}
            value={dayjs(data.expenseDate) as Dayjs}
            handlechange={(dateValue: Dayjs | null) => {
              const formattedDate = dayjs(dateValue).format('MM/DD/YYYY');
              if (dateValue === null) {
                handleDataChange(
                  {
                    ...data,
                    notModified: false,
                    expenseDate: null,
                  },
                  index,
                );
              } else {
                handleDataChange(
                  {
                    ...data,
                    notModified: false,
                    expenseDate: formattedDate,
                  },
                  index,
                );
              }
            }}
            labelRequired={true}
            minDate={minDate}
            maxDate={maxDate}
            disabledDate={status === 'Submitted' || review}
            views={['day']}
          />
        </TableCell>
        <TableCell>
          <DropdownComponent
            names={expenseType}
            value={data.expenseType}
            handleChange={(value: string) => {
              if (value === 'backend.travel_type.travel_mileage') {
                handleDataChange(
                  {
                    ...data,
                    notModified: false,
                    expenseType: value,
                    miles: '',
                    mileageRate: '',
                    amount: '',
                  },
                  index,
                );
              } else {
                handleDataChange(
                  {
                    ...data,
                    notModified: false,
                    expenseType: value,
                    mileageRate: '',
                    amount: '',
                    miles: '',
                  },
                  index,
                );
              }
            }}
            disabled={status === 'Submitted' || review}
            disableClearable
          />
        </TableCell>
        <Tooltip
          title={
            data && data.description && data.description.length > 40
              ? data.description
              : ''
          }
        >
          <TableCell>
            <Textinput
              className="addTravelExpenseDiscriptionfield"
              Value={data.description}
              handlechange={(value: string) => {
                handleDataChange(
                  {
                    ...data,
                    description: value,
                    notModified: false,
                  },
                  index,
                );
              }}
              type="numeric"
              inputProps={{
                maxLength: 200,
              }}
              disabled={status === 'Submitted' || review}
            />
          </TableCell>
        </Tooltip>
        <TableCell>
          <Textinput
            Value={
              data.expenseType === 'backend.travel_type.travel_mileage'
                ? data.mileageRate
                  ? data.mileageRate
                  : mileageRate
                : 'N/A'
            }
            handlechange={(value: string) => {
              handleDataChange(
                {
                  ...data,
                  mileageRate: value,
                  notModified: false,
                },
                index,
              );
            }}
            type="numeric"
            inputProps={{
              maxLength: 4,
            }}
            disabled={true}
          />
        </TableCell>
        <TableCell>
          <Textinput
            Value={
              data.expenseType === 'backend.travel_type.travel_mileage'
                ? data.miles
                : 'N/A'
            }
            handlechange={(value: string) => {
              if (value === '' || /^\d{1,4}(\.\d{0,2})?$/.test(value)) {
                let formattedValue = value;
                // Ensure there are always two digits after the dot
                if (value.includes('.')) {
                  const parts = value.split('.');
                  if (parts[1].length === 0) {
                    formattedValue = `${parts[0]}.00`;
                  }
                } else if (value !== '') {
                  formattedValue = `${value}.00`;
                }

                const travelAmount =
                  data.expenseType === 'backend.travel_type.travel_mileage' &&
                  formattedValue !== ''
                    ? (
                        parseFloat(formattedValue) * parseFloat(mileageRate)
                      ).toFixed(2)
                    : '';

                handleDataChange(
                  {
                    ...data,
                    miles: value,
                    notModified: false,
                    amount: travelAmount,
                  },
                  index,
                );
              }
            }}
            type="numeric"
            inputProps={{
              maxLength: 7,
            }}
            disabled={
              status === 'Submitted' ||
              review ||
              data.expenseType !== 'backend.travel_type.travel_mileage'
            }
          />
        </TableCell>
        <TableCell>
          <Textinput
            Value={
              data.expenseType === 'backend.travel_type.travel_mileage' &&
              data.miles
                ? (parseFloat(data.miles) * parseFloat(mileageRate))
                    .toFixed(2)
                    .toString()
                : data.amount
            }
            handlechange={(value: string) => {
              if (
                value === '' ||
                (/^\d{1,4}(\.\d{0,2})?$/.test(value) &&
                  !(data.expenseType === 'backend.travel_type.travel_mileage'))
              ) {
                handleDataChange(
                  {
                    ...data,
                    notModified: false,
                    amount: value,
                  },
                  index,
                );
              }
            }}
            onPasteText={(e: React.ClipboardEvent<HTMLInputElement>) => {
              const pastedText = e.clipboardData.getData('text');
              let formattedText = pastedText
                .replace(/[^0-9.]/g, '')
                .replace(
                  /^(\d{0,4})\.?(\d{0,2}).*$/,
                  (match, integerPart, decimalPart) => {
                    const paddedIntegerPart = integerPart.padStart(4, '0');
                    const decimalPartWithZeros = decimalPart.padEnd(2, '0');
                    return `${paddedIntegerPart}.${decimalPartWithZeros}`;
                  },
                );
              if (formattedText === '0000.00') {
                formattedText = '';
              }
              handleDataChange(
                {
                  ...data,
                  notModified: false,
                  amount: formattedText,
                },
                index,
              );
              e.preventDefault();
            }}
            disabled={
              data.expenseType === 'backend.travel_type.travel_mileage' ||
              status === 'Submitted' ||
              review
            }
          />
        </TableCell>
        <TableCell>
          <Box component="div" className="flex__ ">
            <Box
              component="div"
              className=" pl-sm pr-sm pt-xs"
              sx={{
                cursor: 'pointer',
                opacity: status === 'Submitted' && !review ? 0.5 : 1,
                pointerEvents:
                  status === 'Submitted' && !review ? 'none' : 'auto',
              }}
              onClick={() => {
                if (!review) {
                  handleOpenAttachmentModal();
                } else if (review && data.attachments.length > 0) {
                  handleOpenAttachmentModal();
                }
              }}
            >
              <ActionToolTip labelId="Attachment" defaultLabel="Attachment">
                {data.attachments.length > 0 ? (
                  <AttachmentGreen />
                ) : (
                  <AttachmentGray />
                )}
              </ActionToolTip>
            </Box>
            <Box
              component="div"
              className={review ? ' pr-md pt-xs' : ' pr-sm pt-xs'}
              sx={{
                cursor: 'pointer',
                opacity: status === 'Submitted' && !review ? 0.5 : 1,
                pointerEvents:
                  status === 'Submitted' && !review ? 'none' : 'auto',
              }}
              onClick={() => {
                if (!review) {
                  handleOpenNoteModal();
                } else if (review && data.note !== '') {
                  handleOpenNoteModal();
                }
              }}
            >
              <ActionToolTip labelId="Note" defaultLabel="Note">
                {data.note !== '' ? (
                  <MessageActive />
                ) : (
                  <MessageIcon stroke={'#97A6A5'} />
                )}
              </ActionToolTip>
            </Box>

            {!review &&
              checkPermissionForFeature(
                'backend.travel_expense',
                'deletePermission',
              ) && (
                <Box
                  component="div"
                  className=" pt-xs pr-sm"
                  sx={{
                    cursor: 'pointer',
                    opacity: status === 'Submitted' ? 0.5 : 1,
                    pointerEvents: status === 'Submitted' ? 'none' : 'auto',
                  }}
                >
                  <ActionToolTip
                    labelId="BlockTime.delete"
                    defaultLabel="Delete"
                  >
                    <DeleteIcon
                      onClick={() => {
                        handleDelete();
                      }}
                    />
                  </ActionToolTip>
                </Box>
              )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExpenseTableRow;
