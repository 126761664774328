import {
  AlertColor,
  Box,
  Card,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useContext, useEffect, useState } from 'react';

import EmptyScreen from '../../components/shared/EmptyScreen';

import { CustomTableHeader, HeadCell } from './CustomTableHeader';
import ExpenseTableRow from './ExpenseTableRow';
import MediumTypography from '../../components/formlib/MediumTypography';
import dayjs from 'dayjs';
import {
  ExpensePostData,
  GetTravelExpenseResponse,
  TravelExpense,
  addEmployeeTravelExpenses,
  deleteAttachmentTravelExpenses,
  deleteEmployeeTravelExpenses,
  getTravelExpenses,
} from '../../services/travelExpenses/TravelExpensesApi';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import React from 'react';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import ModalPopup from '../../components/formlib/ModalPopup';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import MonthViewSelector from './MonthViewSelector';
import YearViewSelector from './YearViewSelector';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

interface TableListData {
  date: string;
  type: string;
  origin: string;
  destination: string;
  destinationCode: string;
  purpose: string;
  miles: string;
  amount: string;
  actions: string;
  description: string;
  milage: string;
}
export const headCells: HeadCell<TableListData>[] = [
  {
    id: 'date',
    labelId: 'travelExpenses.expenseDate',
    defaultLabelId: 'Expense Date',
    numeric: false,
  },
  {
    id: 'type',
    labelId: 'travelExpenses.expenseType',
    defaultLabelId: 'Expense Type',
    numeric: false,
  },
  {
    id: 'description',
    labelId: 'travelExpenses.description',
    defaultLabelId: 'Description',
    numeric: false,
  },
  {
    id: 'milage',
    labelId: 'travelExpenses.milage',
    defaultLabelId: 'Mileage rate ($)',
    numeric: false,
  },
  {
    id: 'miles',
    labelId: 'travelExpenses.miles',
    defaultLabelId: 'Miles',
    numeric: false,
  },
  {
    id: 'amount',
    labelId: 'travelExpenses.amount',
    defaultLabelId: 'Amount ($)',
    numeric: false,
  },
  {
    id: 'actions',
    labelId: 'travelExpenses.actions',
    defaultLabelId: 'Actions',
    numeric: false,
  },
];

const TravelExpenses = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitWarningModal, setSubmitWarningModal] = useState(false);
  const [travelExpenseResponse, setTravelExpenseResponse] =
    useState<GetTravelExpenseResponse>();
  const { loader, toggleLoader } = useContext(
    LoaderContext,
  ) as LoaderContextType;
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [signatureModal, setSignatureModal] = useState(false);
  const [signature, setSignature] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState<string | number>(
    new Date().getFullYear(),
  );
  const [selectedMonth, setSelectedMonth] = useState<string | number>(
    new Date().getMonth() + 1,
  );

  const getTravelExpensesData = () => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);

    if (employeeId === null) {
      return;
    }
    toggleLoader(true);

    getTravelExpenses(
      employeeId,
      selectedMonth as number,
      selectedYear as number,
    )
      .then((response) => {
        if (response) {
          setOpenModal(false);
          toggleLoader(false);
          if (response.expenses.length !== 0) {
            response.expenses.forEach((exp) => {
              exp.isLocal = false;
              exp.notModified = true;
              exp.expenseDate = dayjs(exp.expenseDate, 'YYYY-MM-DD').format(
                'MM/DD/YYYY',
              );
            });
          }
          setTravelExpenseResponse(response);
          setIsSubmit(true);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setOpenModal(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelExpensesFailedToLoadError');
          setToastrDefaultMessage('Failed to load travel expenses');
        }
      });
  };
  useEffect(() => {
    getTravelExpensesData();
  }, [selectedMonth, selectedYear]);

  const getMonthName = (monthNumber: number) => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return monthNames[monthNumber - 1];
  };

  //ADD rows
  const addRowsToExpenseList = (count: number) => {
    const DateSelected = dayjs(
      `${getMonthName(selectedMonth as number)} ${selectedYear}`,
      'MMMM YYYY',
    );
    let newExpenseRows = [];

    if (DateSelected.format('MM YYYY') !== dayjs().format('MM YYYY')) {
      newExpenseRows = Array.from({ length: count }, () => {
        const date = dayjs(`${DateSelected.format('YYYY-MM')}-01`).format(
          'MM/DD/YYYY',
        );
        return {
          description: '',
          mileageRate: travelExpenseResponse?.mileageRate ?? '',
          id: '',
          isLocal: true,
          notModified: false,
          expenseDate: date,
          expenseType: 'backend.travel_type.travel_mileage',
          origin: '',
          destination: '',
          destinationCode: '',
          purpose: 'backend.travel_purpose.conference',
          miles: '',
          amount: '',
          note: '',
          attachments: [],
        };
      });
    } else {
      newExpenseRows = Array.from({ length: count }, () => ({
        description: '',
        mileageRate: travelExpenseResponse?.mileageRate ?? '',
        id: '',
        isLocal: true,
        notModified: false,
        expenseDate: dayjs().format('MM/DD/YYYY'),
        expenseType: 'backend.travel_type.travel_mileage',
        origin: '',
        destination: '',
        destinationCode: '',
        purpose: 'backend.travel_purpose.conference',
        miles: '',
        amount: '',
        note: '',
        attachments: [],
      }));
    }

    if (travelExpenseResponse) {
      if (travelExpenseResponse.expenses.length === 0) {
        setTravelExpenseResponse({
          ...travelExpenseResponse,
          expenses: newExpenseRows,
        });
      } else {
        setTravelExpenseResponse({
          ...travelExpenseResponse,
          expenses: [...travelExpenseResponse.expenses, ...newExpenseRows],
        });
      }
    }
  };

  const handleDeleteExpense = async (itemId: string) => {
    toggleLoader(true);
    deleteEmployeeTravelExpenses(itemId)
      .then(() => {
        toggleLoader(false);
        if (travelExpenseResponse) {
          let idx = 0;
          travelExpenseResponse.expenses.forEach((exp, i) => {
            if (exp.id === itemId) {
              idx = i;
            }
            return exp;
          });
          setTravelExpenseResponse({
            ...travelExpenseResponse,
            totalAmount: getTotalAmountAfterDelete(idx),
            expenses: travelExpenseResponse.expenses.filter(
              (exp) => exp.id !== itemId,
            ),
          });
        }
        setOpen(true);
        setToastrVariable('success');
        setToastrId('travelExpensesDelete.success');
        setToastrDefaultMessage('Travel Expense deleted successfully');
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelExpenses.deleteeError');
          setToastrDefaultMessage('Failed to delete travel expenses');
        }
      });
  };

  useEffect(() => {
    if (travelExpenseResponse?.expenses.length === 0) {
      travelExpenseResponse.travelId = '';
    }
  }, [travelExpenseResponse]);
  const handleSaveExpense = () => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    toggleLoader(true);
    if (employeeId === null || travelExpenseResponse === undefined) {
      return;
    }
    travelExpenseResponse.expenses.forEach((expense) => {
      let formattedValue = expense.miles;
      // Ensure there are always two digits after the dot
      if (expense.miles.includes('.')) {
        const parts = expense.miles.split('.');
        if (parts[1].length === 0) {
          formattedValue = `${parts[0]}.00`;
        }
      } else if (expense.miles !== '') {
        formattedValue = `${expense.miles}.00`;
      }
      expense.miles = formattedValue;
      let formattedEnterdAmount = expense.amount;
      // Ensure there are always two digits after the dot
      if (expense.amount.includes('.')) {
        const parts = expense.amount.split('.');
        if (parts[1].length === 0) {
          formattedEnterdAmount = `${parts[0]}.00`;
        }
      } else if (expense.amount !== '') {
        formattedEnterdAmount = `${expense.amount}.00`;
      }

      expense.amount = formattedEnterdAmount;
      expense.expenseDate = dayjs(expense.expenseDate, 'MM/DD/YYYY').format(
        'YYYY-MM-DD',
      );
    });
    const newExpenses: TravelExpense[] = [];
    travelExpenseResponse.expenses.forEach((expense) => {
      if (expense.id === '') {
        newExpenses.push(expense);
      } else {
        if (!expense.notModified) {
          newExpenses.push(expense);
        }
      }
    });

    const travelExpenseRequestBody: ExpensePostData = {
      expenses: newExpenses,
      travelId: (() => {
        if (travelExpenseResponse.expenses.length === 0) return '';
        if (
          travelExpenseResponse.expenses.every(
            (exp) => exp.id === '' || exp.id === null,
          )
        ) {
          return '';
        }
        return travelExpenseResponse.travelId;
      })(),
      signature: '',
    };

    addEmployeeTravelExpenses(employeeId, travelExpenseRequestBody)
      .then(() => {
        getTravelExpensesData();
        setOpen(true);
        setToastrVariable('success');
        setToastrId('travelExpensesSuccess');
        setToastrDefaultMessage('Travel Expenses added successfully');
        setIsSubmit(true);
      })
      .catch((error) => {
        toggleLoader(false);
        travelExpenseRequestBody.expenses.forEach((expense) => {
          if (expense.isLocal) {
            expense.expenseDate = dayjs(
              expense.expenseDate,
              'YYYY-MM-DD',
            ).format('MM/DD/YYYY');
          }
        });
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelExpenses.error');
          setToastrDefaultMessage('Failed to add travel expenses');
        }
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    setSubmitWarningModal(true);
  };
  const handleSubmitExpense = () => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    toggleLoader(true);
    if (employeeId === null || travelExpenseResponse === undefined) {
      return;
    }

    const travelExpenseRequestBodySubmit: ExpensePostData = {
      expenses: [],
      travelId:
        travelExpenseResponse.expenses.length === 0
          ? ''
          : travelExpenseResponse.travelId,
      signature: signature,
    };

    addEmployeeTravelExpenses(employeeId, travelExpenseRequestBodySubmit)
      .then(() => {
        setSubmitWarningModal(false);
        getTravelExpensesData();

        setOpen(true);
        setToastrVariable('success');
        setToastrId('travelSubmitSuccess');
        setToastrDefaultMessage('Travel Expenses submitted successfully');
        setIsSubmit(true);
      })
      .catch((error) => {
        toggleLoader(false);
        setSubmitWarningModal(true);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelsubmitExpenses.error');
          setToastrDefaultMessage('Failed to submit travel expenses');
        }
      });
  };

  const handleCancel = () => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);

    if (employeeId === null) {
      return;
    }
    toggleLoader(true);
    deleteAttachmentTravelExpenses(employeeId)
      .then(() => {
        getTravelExpensesData();
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenModal(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelExpensesFailedToLoadError');
          setToastrDefaultMessage('Failed to load travel expenses');
        }
      });
  };
  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpen(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const getTotalAmountAfterDelete = (idx: number) => {
    let currentAmount: number = 0.0;
    if (travelExpenseResponse) {
      travelExpenseResponse.expenses.forEach((exp, i) => {
        if (i !== idx && exp.amount) {
          currentAmount += parseFloat(exp.amount);
        }
        return exp;
      });
    }
    currentAmount = Math.round(currentAmount * 10 ** 10) / 10 ** 10;
    return currentAmount.toString();
  };

  useEffect(() => {
    if (!isSubmit) {
      handleUpdateUnsavedChanges(true);
    } else {
      handleUpdateUnsavedChanges(false);
    }
  }, [isSubmit]);

  return (
    <Box component="main">
      <Box component="section">
        <ModalPopup
          open={openModal}
          description="formUnsavedChangesMessage"
          onCancel={() => setOpenModal(false)}
          onOk={() => {
            handleCancel();
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="Cancel"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="Ok"
        />
        {
          <SnackBarComponent
            open={open}
            handleClose={handleClose}
            successOrError={toastrVariable}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
          />
        }
        {submitWarningModal &&
        parseFloat(travelExpenseResponse?.totalAmount ?? '0.0') === 0.0 ? (
          <ModalPopup
            open={submitWarningModal}
            description="travelsubmitWarning"
            onCancel={() => setSubmitWarningModal(false)}
            labelId1="Close"
            negativeActionLabel="Close"
            hidePositiveBtn={true}
          />
        ) : (
          <ModalPopup
            open={submitWarningModal}
            description="travelsubmission"
            onCancel={() => setSubmitWarningModal(false)}
            onOk={() => {
              setSubmitWarningModal(false);
              handleSubmitExpense();
            }}
            labelId1="TimeOff.No"
            negativeActionLabel="No"
            labelId2="TimeOff.Yes"
            positiveActionLabel="Yes"
          />
        )}
        <Box className="flex__ justifyContentSpaceBtw align__items__center mt-md mb-lg">
          <Box className="flex__ ">
            <TitleText
              labelid="travelExpense.title"
              defaultlabel="Add Travel Expense"
            />
            {travelExpenseResponse?.status === 'Not submitted' && isSubmit && (
              <Box className="flex__ alignItemFlexEnd ml-md">
                <Box className="progressDot mb-xs" marginRight={1}></Box>
                <MediumTypography
                  label={travelExpenseResponse?.status}
                  fontSize="16px"
                  textColor="#97A6A5"
                />
              </Box>
            )}
            {travelExpenseResponse?.status === 'Submitted' && (
              <Box className="flex__ alignItemFlexEnd ml-md">
                <Box
                  sx={{ backgroundColor: '#37D183' }}
                  className="activeDot mb-xs"
                  marginRight={1}
                ></Box>
                <MediumTypography
                  label={travelExpenseResponse?.status}
                  fontSize="16px"
                  textColor="#37D183"
                />
              </Box>
            )}
            {travelExpenseResponse?.status === 'Approved' && (
              <Box className="flex__ alignItemFlexEnd ml-md">
                <Box
                  sx={{ backgroundColor: '#37D183' }}
                  className="activeDot mb-xs"
                  marginRight={1}
                ></Box>
                <MediumTypography
                  label={travelExpenseResponse?.status}
                  fontSize="16px"
                  textColor="#37D183"
                />
              </Box>
            )}
            {travelExpenseResponse?.status === 'Sent To Finance' && (
              <Box className="flex__ alignItemFlexEnd ml-md">
                <Box
                  sx={{ backgroundColor: '#EB4C60' }}
                  className="activeDot mb-xs"
                  marginRight={1}
                ></Box>
                <MediumTypography
                  label={travelExpenseResponse?.status}
                  fontSize="16px"
                  textColor="#EB4C60"
                />
              </Box>
            )}
            {travelExpenseResponse?.status === 'Rejected' && (
              <Box className="flex__ alignItemFlexEnd ml-md">
                <Box
                  sx={{ backgroundColor: '#EB4C60' }}
                  className="activeDot mb-xs"
                  marginRight={1}
                ></Box>
                <MediumTypography
                  label={travelExpenseResponse?.status}
                  fontSize="16px"
                  textColor="#EB4C60"
                />
              </Box>
            )}
          </Box>

          <Box className="display-flex-center" gap={2}>
            <MonthViewSelector
              initialMonth={new Date().getMonth()}
              onMonthChange={(mon) => {
                setSelectedMonth(`${mon + 1}`);
              }}
            />
            <YearViewSelector
              value={selectedYear}
              onChange={(value) => {
                setSelectedYear(value);
              }}
            />
          </Box>
        </Box>

        {travelExpenseResponse && (
          <>
            <Box component="div">
              <Box component="div" className="mb-md">
                {travelExpenseResponse.expenses.length === 0 ? (
                  <EmptyScreen
                    titleLabelId="TravelExpenses.emptyMessage"
                    defaultTitleText="No Travel Expenses added yet."
                    buttonLabelId="travelExpense.header"
                    defaultButtonTitleText="Add Travel Expenses"
                    showButton={
                      checkPermissionForFeature(
                        'backend.travel_expense',
                        'editPermission',
                      ) ||
                      checkPermissionForFeature(
                        'backend.travel_expense',
                        'addPermission',
                      )
                    }
                    onButtonClick={() => {
                      addRowsToExpenseList(5);
                      setIsSubmit(false);
                    }}
                  />
                ) : (
                  <Card className="formCardview">
                    <Box className="flex__ justifyContentSpaceBtw">
                      {checkPermissionForFeature(
                        'backend.travel_expense',
                        'editPermission',
                      ) ||
                      checkPermissionForFeature(
                        'backend.travel_expense',
                        'addPermission',
                      ) ? (
                        <Box
                          sx={{
                            borderBottom:
                              travelExpenseResponse.status === 'Submitted' ||
                              travelExpenseResponse.status === 'Approved' ||
                              travelExpenseResponse.status === 'Sent To Finance'
                                ? '2px solid #97A6A5'
                                : '2px solid #00C6B8',
                            cursor:
                              travelExpenseResponse.status === 'Submitted' ||
                              travelExpenseResponse.status === 'Approved' ||
                              travelExpenseResponse.status === 'Sent To Finance'
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                          width={'100px'}
                          className="mb-md "
                          onClick={() => {
                            if (
                              travelExpenseResponse.status !== 'Submitted' &&
                              travelExpenseResponse.status !== 'Approved' &&
                              travelExpenseResponse.status !== 'Sent To Finance'
                            ) {
                              addRowsToExpenseList(1);
                              setIsSubmit(false);
                            }
                          }}
                        >
                          <MediumTypography
                            labelid="TravelExpenses.addRows"
                            defaultlabel="+ Add Row(s)"
                            sxProps={{
                              color:
                                travelExpenseResponse.status === 'Submitted' ||
                                travelExpenseResponse.status === 'Approved' ||
                                travelExpenseResponse.status ===
                                  'Sent To Finance'
                                  ? '#97A6A5'
                                  : '#00C6B8',
                              fontSize: '16px',
                              fontWeight: 700,
                            }}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <MediumTypography label="" />
                        </Box>
                      )}
                      <Box className="flex__ ">
                        <MediumTypography
                          labelid="travelExpense.totalAmount"
                          defaultlabel="Total Amount ($):"
                          textColor="#97A6A5"
                          fontweight={700}
                        />
                        <MediumTypography
                          label={
                            travelExpenseResponse.totalAmount !== ''
                              ? travelExpenseResponse.totalAmount
                              : '0.0'
                          }
                          fontweight={700}
                          className="pl-xs"
                          sxProps={{
                            paddingBottom:
                              checkPermissionForFeature(
                                'backend.travel_expense',
                                'editPermission',
                              ) ||
                              checkPermissionForFeature(
                                'backend.travel_expense',
                                'addPermission',
                              )
                                ? '0px'
                                : '10px',
                          }}
                        />
                      </Box>
                    </Box>
                    <TableContainer sx={{ height: '400px', maxHeight: 'auto' }}>
                      <Table stickyHeader aria-label="sticky table">
                        <CustomTableHeader
                          className="addTravelExpenseFirstcolmn"
                          headerNames={headCells}
                          numSelected={0}
                          labelSxProp={{
                            paddingLeft: '10px',
                          }}
                        />
                        <TableBody className="tableRowcss travelTablebody ">
                          {travelExpenseResponse.expenses.map(
                            (expense, index) => (
                              <ExpenseTableRow
                                data={expense}
                                index={index}
                                onDeleteExpense={(data, idx) => {
                                  setIsSubmit(false);
                                  if (data.isLocal) {
                                    setTravelExpenseResponse({
                                      ...travelExpenseResponse,
                                      totalAmount:
                                        getTotalAmountAfterDelete(idx),
                                      expenses:
                                        travelExpenseResponse.expenses.filter(
                                          (_, i) => i !== idx,
                                        ),
                                    });
                                  } else {
                                    handleDeleteExpense(expense.id);
                                  }
                                }}
                                handleDataChange={(data, i) => {
                                  const exps = travelExpenseResponse.expenses;
                                  exps[i] = data;

                                  if (data.isLocal || !data.notModified) {
                                    setIsSubmit(false);
                                  }
                                  const totalAmount = exps.reduce(
                                    (total, exp) => {
                                      if (exp.amount) {
                                        return total + parseFloat(exp.amount);
                                      }
                                      return total;
                                    },
                                    0,
                                  );
                                  const calculatedAmount = totalAmount
                                    .toFixed(2)
                                    .toString();

                                  setTravelExpenseResponse({
                                    ...travelExpenseResponse,
                                    expenses: exps,
                                    totalAmount: calculatedAmount,
                                  });
                                }}
                                mileageRate={travelExpenseResponse.mileageRate}
                                status={travelExpenseResponse.status}
                                month={getMonthName(selectedMonth as number)}
                                year={selectedYear.toString()}
                                review={
                                  travelExpenseResponse.status ===
                                    'Submitted' ||
                                  travelExpenseResponse.status ===
                                    'Sent To Finance' ||
                                  travelExpenseResponse.status === 'Approved' ||
                                  (checkPermissionForFeature(
                                    'backend.travel_expense',
                                    'ViewPermission',
                                  ) &&
                                    !checkPermissionForFeature(
                                      'backend.travel_expense',
                                      'editPermission',
                                    ))
                                }
                              />
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                )}
              </Box>
              {travelExpenseResponse.expenses.length > 0 && isSubmit && (
                <Box
                  component="div"
                  className="flex__  pb-lg alignItemsCenter justifyContentSpaceBtw"
                >
                  {signature !== '' ||
                  travelExpenseResponse?.signatureRowKey !== '' ? (
                    <ButtonComponent
                      className="btn-primary btn-cancel signature_view_Box"
                      labelId="Insurance.Viewsignature"
                      defaultLabelId="View/update signature"
                      variantType="outlined"
                      LeftIcon={<Editsignature />}
                      onClick={() => {
                        setSignatureModal(true);
                      }}
                    />
                  ) : (
                    <ButtonComponent
                      className="btn-primary btn-cancel signature_add_Box"
                      labelId="Insurance.Addsignature"
                      defaultLabelId="Add Signature"
                      variantType="outlined"
                      disabled={
                        !checkPermissionForFeature(
                          'backend.travel_expense',
                          'addPermission',
                        )
                      }
                      onClick={() => {
                        setSignatureModal(true);
                      }}
                    />
                  )}
                  {travelExpenseResponse.rejectionReason !== '' &&
                    travelExpenseResponse.rejectionReason !== null &&
                    (travelExpenseResponse.status === 'Rejected' ||
                      travelExpenseResponse.status === 'Not submitted') && (
                      <Box
                        className=" align__items__center justify__content__center"
                        sx={{ width: '50%' }}
                      >
                        <Box
                          component="div"
                          sx={{
                            alignItems: 'flex-start',
                          }}
                        >
                          <MediumTypography
                            labelid="travelExpense.rejectionNote"
                            defaultlabel="Rejection Note:"
                            textColor="#EB4C60"
                            fontSize="16px"
                            fontweight={600}
                            sxProps={{
                              whiteSpace: 'nowrap',
                              marginRight: '8px',
                            }}
                          />
                          <MediumTypography
                            label={travelExpenseResponse.rejectionReason}
                            textColor="#2a4241"
                            fontSize="14px"
                            sxProps={{
                              wordWrap: 'break-word',
                              flex: 1,
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  <Box className="flex__ align__items__center">
                    <ButtonComponent
                      className="btn-primary btn-submit  ml-md"
                      labelId="Submit"
                      defaultLabelId="Submit"
                      variantType="contained"
                      onClick={handleSubmit}
                      disabled={
                        signature === '' ||
                        travelExpenseResponse?.status === 'Submitted'
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {signatureModal && (
              <Box component="div">
                <SignatureComponent
                  onCancelClick={() => {
                    setSignatureModal(false);
                  }}
                  signatureRowKey={
                    travelExpenseResponse?.signatureRowKey !== ''
                      ? travelExpenseResponse?.signatureRowKey
                      : ''
                  }
                  signaturePartitionKey={
                    travelExpenseResponse?.signatureRowKey !== ''
                      ? 'travelExpense'
                      : ''
                  }
                  signatureSaveOrUPdate={(sign: string) => {
                    setSignature(sign);
                    setSignatureModal(!signatureModal);
                  }}
                  handleSuccess={handleSuccess}
                  type="Employee"
                  signature={signature}
                  signClicked={signatureModal}
                  resetCallBackForTypeEmployee={() => {
                    setSignatureModal(false);
                    setSignature('');
                  }}
                  hideRemoveButton={
                    travelExpenseResponse.signatureRowKey !== ''
                  }
                />
              </Box>
            )}
            {travelExpenseResponse.expenses.length > 0 &&
              !isSubmit &&
              checkPermissionForFeature(
                'backend.travel_expense',
                'addPermission',
              ) && (
                <Box component="div" className="flex__ justifyContent-FlexEnd ">
                  <Box component="div" className="flex__ alignItemCenter">
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      labelId={'Cancel'}
                      defaultLabelId="Cancel"
                      variantType="outlined"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    />
                    <ButtonComponent
                      disabled={
                        travelExpenseResponse.status === 'Submitted' ||
                        travelExpenseResponse.status === 'Approved' ||
                        !checkPermissionForFeature(
                          'backend.travel_expense',
                          'addPermission',
                        )
                      }
                      className="btn-primary btn-submit  ml-md"
                      labelId="ConsentSave"
                      defaultLabelId="Save"
                      variantType="contained"
                      loading={loader}
                      onClick={() => {
                        if (
                          travelExpenseResponse.expenses.some((exp) => {
                            const expenseRowDate = dayjs(
                              exp.expenseDate,
                            ).format('MMMM YYYY');
                            return (
                              !dayjs(expenseRowDate).isValid() ||
                              exp.expenseDate === '' ||
                              exp.expenseDate === 'Invalid date' ||
                              exp.expenseDate === 'null'
                            );
                          })
                        ) {
                          setOpen(true);
                          setToastrVariable('error');
                          setToastrDefaultMessage('Expense Date is Required');
                          setToastrId('travelExpense.dateRequiredError');
                        } else if (
                          travelExpenseResponse.expenses.some((exp) => {
                            const expenseRowDate = dayjs(
                              exp.expenseDate,
                            ).format('MMMM YYYY');
                            return !dayjs(expenseRowDate).isSame(
                              dayjs(
                                `${getMonthName(
                                  selectedMonth as number,
                                )} ${selectedYear}`,
                              ).format('MMMM YYYY'),
                            );
                          })
                        ) {
                          setOpen(true);
                          setToastrVariable('error');
                          setToastrDefaultMessage(
                            "Expense Date doesn't match with selected month",
                          );
                          setToastrId('travelExpense.dateDoesNotMatchError');
                        } else if (
                          travelExpenseResponse.expenses.some((exp) => {
                            const pickerMin = '01/01/1900';
                            const pickerMax = '12/31/2099';

                            return (
                              dayjs(exp.expenseDate).isBefore(pickerMin) ||
                              dayjs(exp.expenseDate).isAfter(pickerMax)
                            );
                          })
                        ) {
                          setOpen(true);
                          setToastrVariable('error');
                          setToastrDefaultMessage('Expense Date is invalid');
                          setToastrId('datePickerMinDateMaxDateValidate');
                        } else {
                          handleSaveExpense();
                        }
                      }}
                    />
                  </Box>
                </Box>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TravelExpenses;
