import { createContext, ReactNode, useState } from 'react';

type UnSavedChangesProviderType = {
  children: ReactNode;
};
export type UnSavedChangesContextType = {
  hasUnsavedChanges: boolean;
  handleUpdateUnsavedChanges: (value: boolean) => void;
};

const UnSavedChangesContext = createContext<UnSavedChangesContextType>({
  hasUnsavedChanges: false,
  handleUpdateUnsavedChanges: () => {},
});

const UnSavedChangesProvider = ({ children }: UnSavedChangesProviderType) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const handleUpdateUnsavedChanges = (value: boolean) => {
    setHasUnsavedChanges(value);
  };
  return (
    <UnSavedChangesContext.Provider
      value={{
        hasUnsavedChanges,
        handleUpdateUnsavedChanges,
      }}
    >
      {children}
    </UnSavedChangesContext.Provider>
  );
};

export { UnSavedChangesContext, UnSavedChangesProvider };
