import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AlertColor,
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  styled,
} from '@mui/material';
import SearchBox from '../../components/formlib/SearchBox';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import TitleText from '../../components/formlib/TitleText';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/downloadIcon.svg';
import { ChipData, ResourceList, ResourceResponseList } from '../../utils/type';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { v4 as uuidv4 } from 'uuid';
import {
  deleteResourceFile,
  getResourceRepoDetails,
  orphanedDelete,
  uploadResourceFilesFile,
} from '../../services/configApi/resourceRepository/resourceRepository';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import FilePreviewDialog from '../../components/formlib/modal/FilePreviewDialog';
import { extractFileExtension } from '../../utils/fileUtils';
import { PREVIEW_DOCUMENT_EXTENSIONS } from '../../services/Constant';
import moment from 'moment';
import { ReactComponent as TickIcon } from '../../assets/images/checkResourceRepository.svg';
import { ReactComponent as FileIcon } from '../../assets/images/fileIcon.svg';
import AppPagination from '../../components/shared/AppPagination';
import EmptyScreen from '../../components/shared/EmptyScreen';
import { debounce } from 'lodash';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import ModalPopup from '../../components/formlib/ModalPopup';
import MediumTypography from '../../components/formlib/MediumTypography';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';

let uuid: string;
let foundUUID = false;

const headCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'resourceRepositortResponseList.RowFiles',
    defaultLabelId: 'Files',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'type',
    labelId: 'resourceRepositortResponseList.RowTypes',
    defaultLabelId: 'Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'createdOn',
    labelId: 'resourceRepositortResponseList.RowUploadDate',
    defaultLabelId: 'Upload date',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'uploadBy',
    labelId: 'resourceRepositortResponseList.RowUploadBy',
    defaultLabelId: 'Upload By',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'resourceRepositortResponseList.Actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

export type Data = {
  name: string;
  createdOn: string;
  uploadBy: string;
  actions: string;
  type: string;
};

const FileType = [
  {
    value: 'Poverty Levels doc',
    labelId: 'povertyLevelFileType',
    defaultLabel: 'Poverty Levels doc',
  },
  {
    value: 'Manual',
    labelId: 'ManualFileType',
    defaultLabel: 'Manual',
  },
  {
    value: 'Others',
    labelId: 'OthersFileType',
    defaultLabel: 'Others',
  },
];

const ResourceRepository = () => {
  type Order = 'asc' | 'desc';

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof Data | string>('');
  const [order, setOrder] = useState<Order>('desc');
  const [responseDetails, setresponseDetails] =
    useState<ResourceResponseList>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<ResourceList>();
  const [responseDetailsCount, setResponseDetailsCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowId, setRowId] = useState<string>('');
  const [fileType, setFileType] = useState<string | null>('Others');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const uploadCancelledRef = useRef(false);
  const CustomTableCell = styled(TableCell)(() => ({
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '14px 16px',
  }));

  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files !== null && files.length > 0) {
      const fileChips: ChipData[] = [...chipData];
      const fileList: File[] = [...selectedFiles];
      const maxFiles = 5;
      const remainingSlots = maxFiles - selectedFiles.length;
      const filesToAdd = Math.min(files.length, remainingSlots);
      for (let i = 0; i < filesToAdd; i++) {
        const file = files[i];

        if (file.type !== 'application/pdf') {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('resourceRepositortResponseList.OnlyPDF');
          setToastrDefaultMessage('resourceRepositortResponseList.OnlyPDF');
          continue;
        }

        if (file.size > 25 * 1024 * 1024) {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('resourceRepositortResponseList.FileLimit');
          setToastrDefaultMessage('resourceRepositortResponseList.FileLimit');
          continue;
        }

        fileList.push(file);
        fileChips.push({
          key: uuidv4(),
          label: file.name,
          status: 'default',
        });
      }
      setChipData(fileChips);
      setSelectedFiles(fileList);
      setModalVisible(fileList.length > 0);
    }
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  React.useEffect(() => {
    if (selectedFiles.length > 0 && !uploadCancelledRef.current) {
      uploadFiles();
    }
  }, [selectedFiles, uploadCancelledRef.current]);

  useEffect(() => {
    getResourceList();
  }, [page, order, orderBy, searchQuery]);

  const getResourceList = () => {
    toggleLoader(true);
    getResourceRepoDetails(page, 10, orderBy, order, searchQuery)
      .then((response: ResourceResponseList) => {
        toggleLoader(false);
        setresponseDetails(response);
        setResponseDetailsCount(response.totalResourcesCount);
        setShowEmptyScreen(response.totalResourcesCount === 0 ? true : false);
        if (response.hardDelete === true) {
          orphanedDelete();
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setToastrVariable('error');
        setOpen(true);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('failedResourceRepositoryMessage');
          setToastrDefaultMessage('Failed to load Resource Repository');
        }
      });
  };

  const uploadFiles = async (index = 0) => {
    if (uploadCancelledRef.current) {
      return;
    }

    const failedFiles: ChipData[] = [];
    if (index >= selectedFiles.length) {
      toggleLoader(true);
      setTimeout(() => {
        getResourceList();
      }, 2000);
      setModalVisible(false);
      setSelectedFiles([]);
      setChipData([]);
      setOpen(true);
      setToastrId('resourceRepositortResponseList.AllFilesUploaded');
      setToastrDefaultMessage('All files successfully uploaded');
      setToastrVariable('success');
      return;
    }

    const file = selectedFiles[index];
    const chipId = chipData[index].key;

    try {
      await uploadResourceFilesFile(file, chipId, fileType);
      setChipData((chips) =>
        chips.map((chip) => {
          if (chip.key === chipId) {
            return { ...chip, status: 'success' };
          }
          return chip;
        }),
      );
    } catch (error) {
      setChipData((chips) =>
        chips.map((chip) => {
          if (chip.key === chipId) {
            failedFiles.push(chip);
            return { ...chip, status: 'error' };
          }
          return chip;
        }),
      );
    }

    if (uploadCancelledRef.current) {
      return;
    } else {
      uploadFiles(index + 1);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const inputFileRef = useRef<HTMLInputElement>(null);

  const browseFiles = () => {
    uploadCancelledRef.current = false;
    inputFileRef.current?.click();
  };

  const cancelFunction = () => {
    uploadCancelledRef.current = true;
    let uploadedCount = 0;
    for (let i = 0; i < chipData.length; i++) {
      if (chipData[i].status === 'default') {
        uuid = chipData[i].key;
        foundUUID = true;
        break;
      }

      if (chipData[i].status === 'success') {
        uploadedCount++;
      }
    }
    setSelectedFiles([]);
    setChipData([]);
    setModalVisible(false);
    if (uuid && foundUUID) {
      if (uploadedCount === 0) {
        setOpen(true);
        setToastrVariable('error');
        setToastrId(`No files uploaded`);
        setToastrDefaultMessage(`No files uploaded`);
        setTimeout(() => {
          deleteResourceFile(uuid);
        }, 2000);
      } else {
        toggleLoader(true);
        setTimeout(() => {
          deleteFile(uploadedCount);
        }, 2000);
      }
    }
  };

  const deleteFile = (uploadedCount: number) => {
    deleteResourceFile(uuid)
      .then(() => {
        setOpen(true);
        setToastrVariable('success');
        setToastrId(`${uploadedCount} files uploaded successfully`);
        setToastrDefaultMessage(`${uploadedCount} files uploaded successfully`);
        toggleLoader(true);
        foundUUID = false;
        setTimeout(() => {
          getResourceList();
        }, 2000);
      })
      .catch((error) => {
        setOpen(true);
        setToastrVariable('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error.message);
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage - 1 !== page) {
      setPage(newPage - 1);
      setresponseDetails((prevValues) => ({
        ...(prevValues || {
          resources: [],
          totalResourcesCount: 0,
          hardDelete: false,
        }),
        resources: [],
        totalResourcesCount: prevValues?.totalResourcesCount || 0,
        hardDelete: prevValues?.hardDelete || false,
      }));
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const deleteFunction = (identifier: string) => {
    deleteResourceFile(identifier)
      .then(() => {
        setOpen(true);
        setDeleteModal(false);
        setToastrVariable('success');
        setToastrId(`resourceRepositortResponseList.RecordDeleted`);
        setToastrDefaultMessage(`Record Deleted Successfully`);
        if (responseDetails?.resources.length === 1 && page > 0) {
          setPage(page - 1);
        } else {
          getResourceList();
        }
      })
      .catch((error) => {
        setOpen(true);
        setDeleteModal(false);
        setToastrVariable('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error.message);
      });
  };

  const downloadFunction = (url: string, name: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePreviewClick = (fileItem: ResourceList) => {
    setShowPreview(true);
    setSelectedFile(fileItem);
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const changeTextDebouncer = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPage(0);
        setSearchQuery(value);
      } else {
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
      }
    }, 500),
    [],
  );

  return (
    <Box component="main">
      {selectedFile && showPreview && (
        <FilePreviewDialog
          url={selectedFile.resourceUrl}
          fileName={selectedFile.name.split('/').pop()}
          onClose={() => {
            setShowPreview(false);
            setSelectedFile(undefined);
          }}
        />
      )}
      <Box component="section">
        {searchQuery !== '' &&
          responseDetails?.resources.length === 0 &&
          showEmptyScreen && <SearchNotFound />}
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={6} lg={6}>
              <TitleText
                labelid="resourceRepositortResponseList"
                defaultlabel="Resource Repository"
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Box
                component="div"
                className="flex__ text-align-end justifyContent-FlexEnd"
              >
                <SearchBox
                  labelId="resourceRepositortResponseList.SearchFiles"
                  defaultlabel="Search Files"
                  onChange={changeTextDebouncer}
                />
                <input
                  ref={inputFileRef}
                  type="file"
                  multiple
                  accept=".pdf"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                {checkPermissionForFeature(
                  'backend.resource_repository',
                  'addPermission',
                ) && (
                  <ButtonComponent
                    className="btn-primary btn-submit ml-md"
                    variantType="contained"
                    type="submit"
                    labelId="resourceRepositortResponseList.UploadFiles"
                    onClick={() => {
                      setFileType('Others');
                      setOpenModal(true);
                    }}
                    disabled={false}
                    defaultLabelId="Save"
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box component="div">
          {responseDetails?.resources.length !== 0 && (
            <Card>
              <TableContainer>
                <Table>
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells}
                    checkBoxRequired={false}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody className="tableRowcss">
                    {responseDetails?.resources.map((row) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          sx={{
                            cursor: 'pointer',
                            alignContent: 'flex-start',
                            '&:nth-of-type(odd)': {
                              backgroundColor: '#ECF9F8',
                            },
                          }}
                        >
                          <TooltipTableCell
                            textSxProps={{ maxWidth: '200px' }}
                            onClick={() => {
                              const extension = extractFileExtension(
                                row.resourceUrl,
                              ).toLowerCase();
                              if (
                                PREVIEW_DOCUMENT_EXTENSIONS.includes(extension)
                              ) {
                                handlePreviewClick(row);
                              }
                            }}
                            value={row.name}
                            visible={true}
                            className="cursor__pointer"
                          />
                          <TooltipTableCell value={row.type} visible={true} />
                          <TooltipTableCell
                            value={moment(row.uploadedOn).format('MM/DD/YYYY')}
                            visible={true}
                          />
                          <TooltipTableCell
                            value={row.uploadedBy}
                            visible={true}
                          />
                          <CustomTableCell className="ellipsis">
                            <Box className="flex__ justifyContent-FlexStart alignItemCenter">
                              {checkPermissionForFeature(
                                'backend.resource_repository',
                                'deletePermission',
                              ) && (
                                <Box className="cursorPointer mr-md">
                                  <Tooltip
                                    id="tool-tip"
                                    title={'Delete'}
                                    placement="bottom-start"
                                  >
                                    <DeleteIcon
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setRowId(row.uiIdentifier);
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              )}
                              <Box className="cursorPointer mr-md">
                                <Tooltip
                                  id="tool-tip"
                                  title={'Download'}
                                  placement="bottom-start"
                                >
                                  <DownloadIcon
                                    onClick={() =>
                                      downloadFunction(
                                        row.resourceUrl,
                                        row.name,
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          </CustomTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Box>
        {showEmptyScreen && searchQuery == '' && (
          <EmptyScreen
            titleLabelId="resourceRepositortResponseList.NoFilesFound"
            defaultTitleText="No forms added yet."
            buttonLabelId="resourceRepositortResponseList.NoFilesFound"
            defaultButtonTitleText="Create"
            showButton={false}
          />
        )}
      </Box>

      <Box component="div">
        <Dialog
          maxWidth="xs"
          open={modalVisible}
          keepMounted
          onClose={() => ({})}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="p-md">
            {chipData.map((data) => {
              return (
                <Box className="flex__ align__items__center">
                  <Grid
                    className="flex__ justifyContent-FlexStart pt-md pb-lg "
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <Box className="flex__ align__items__center">
                        <Box>
                          {' '}
                          <FileIcon />
                        </Box>
                        <Box className="pl-md">{data.label}</Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                    container
                    direction="row"
                    alignItems="right"
                  >
                    {' '}
                    <Grid item>
                      <Box>
                        {data.status === 'default' ? (
                          <CircularProgress
                            sx={{ color: '#00938E' }}
                            size={12}
                          />
                        ) : (
                          <TickIcon />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
            <Box>
              <Grid
                className="flex__ justifyContent-FlexEnd pt-lg "
                container
                direction="row"
                alignItems="right"
              >
                <Grid item>
                  <ButtonComponent
                    className="btn-primary btn-cancel btn_width_90px"
                    variantType="outlined"
                    labelId="cancelText"
                    defaultLabelId="Cancel"
                    onClick={() => cancelFunction()}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
        {deleteModal && (
          <ModalPopup
            open={deleteModal}
            onCancel={() => {
              setDeleteModal(false);
            }}
            description="resourceRepositortResponseList.DeleteRecord"
            onOk={() => deleteFunction(rowId)}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}
      </Box>
      {responseDetailsCount > 10 && (
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AppPagination
              pageNumber={page}
              paginationCount={responseDetailsCount}
              handleChangePage={handleChangePage}
            />
          </Box>
        </Grid>
      )}

      <Box component="div">
        <Dialog
          open={openModal}
          keepMounted
          onClose={() => ({})}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="p-md">
            <MediumTypography
              labelid={'MyTimeOffSelectType'}
              defaultlabel="Select Type"
              fontweight={1000}
              textColor="#2a4241"
            />
            <Box component="div">
              <Box component="div" className="mt-lg">
                <CustomRadioButton
                  disable={false}
                  data={FileType}
                  value={fileType}
                  onSelectItem={(value) => {
                    setFileType(value);
                  }}
                  row={false}
                />
              </Box>
              <Box>
                <Grid
                  className="flex__ justifyContent-FlexEnd pt-lg "
                  container
                  direction="row"
                  alignItems="right"
                >
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-cancel btn_width_90px mr-md"
                      variantType="outlined"
                      labelId="cancelText"
                      defaultLabelId="Cancel"
                      onClick={() => setOpenModal(false)}
                    />

                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      labelId="selectFileButton"
                      defaultLabelId="Select File"
                      onClick={() => {
                        browseFiles();
                        setOpenModal(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ResourceRepository;
