import { Box, Card, Grid } from '@mui/material';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import Textinput from '../../components/formlib/Textinput';
import { ReactComponent as DeleteAlertIcon } from '../../assets/images/deleteAlertIcon.svg';
import { Formik, FormikProps } from 'formik';
import dayjs from 'dayjs';
import * as yup from 'yup';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  datePickerMinDateMaxDateValidate,
  formatDate,
  getFinancialYear,
  getFinancialYearJuneEndDate,
} from '../../utils/dateUtil';
import {
  Holiday,
  HolidayRequest,
  createHolidayList,
  deleteHoliday,
  getHolidayList,
  updateHolidayList,
} from '../../services/configApi/employees/holidayServices';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ResponseType } from '../../utils/type';
import ModalPopup from '../../components/formlib/ModalPopup';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { useNavigate } from 'react-router';
import { PROGRAM_MANAGEMENT_ROUTE_NAME } from '../../routes/Routing';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

const validationSchema = yup.array().of(
  yup.object().shape({
    holidayDate: yup
      .string()
      .required('dateRequiredErrorMessage')
      .test('is-unique', 'duplicateDateMessage', function (value, context) {
        const values = context.options.context?.map(
          (obj: { holidayDate: string; title: string }) => obj.holidayDate,
        ); // get all values to check
        return values.filter((val: string) => val === value).length <= 1; // check if value exists only once
      })
      .nullable(),
    title: yup.string().required('titleRequiredErrorMessage'),
  }),
);

const validateForm = (value: Holiday[]): Partial<Holiday>[] => {
  const errors: Partial<Holiday>[] = [];

  value.forEach((holiday, index) => {
    if (datePickerMinDateMaxDateValidate(holiday.holidayDate)) {
      if (!errors[index]) errors[index] = {};
      errors[index].holidayDate = 'datePickerMinDateMaxDateValidate';
    }

    if (holiday.holidayDate === null) {
      if (!errors[index]) errors[index] = {};
      errors[index].holidayDate = 'dateRequiredErrorMessage';
    }
    if (holiday.title === '') {
      if (!errors[index]) errors[index] = {};
      errors[index].title = 'titleRequiredErrorMessage';
    }

    if (holiday.holidayDate) {
      // check for past date
      if (
        holiday.isPastDate &&
        dayjs(value[index].holidayDate).isBefore(
          dayjs(value[index].holidayDate).format('MM/DD/YYYY'),
        )
      ) {
        if (!errors[index]) errors[index] = {};
        errors[index].holidayDate = 'validDate';
      } else if (
        !holiday.isPastDate &&
        dayjs(value[index].holidayDate).isBefore(dayjs().format('MM/DD/YYYY'))
      ) {
        if (!errors[index]) errors[index] = {};
        errors[index].holidayDate = 'validDate';
      }

      // check for future date
      if (
        dayjs(value[index].holidayDate).isAfter(getFinancialYearJuneEndDate())
      ) {
        if (!errors[index]) errors[index] = {};
        errors[index].holidayDate = 'validDate';
      }

      // check for duplicate
      const holidayDates = value.map((obj) => obj.holidayDate);
      if (
        holidayDates.filter((val) => val === holiday.holidayDate).length > 1
      ) {
        if (!errors[index]) errors[index] = {};
        errors[index].holidayDate = 'duplicateDateMessage';
      }
    }
  });

  return errors;
};

interface HolidayProp {
  programId: string;
}

const CreateHolidayList: FC<HolidayProp> = ({ programId }) => {
  const initialValues: Holiday[] = [
    {
      id: '',
      holidayDate: null,
      title: '',
      isPastDate: false,
    },
    {
      id: '',
      holidayDate: null,
      title: '',
      isPastDate: false,
    },
    {
      id: '',
      holidayDate: null,
      title: '',
      isPastDate: false,
    },
    {
      id: '',
      holidayDate: null,
      title: '',
      isPastDate: false,
    },
    {
      id: '',
      holidayDate: null,
      title: '',
      isPastDate: false,
    },
  ];

  const rctl = useIntl();
  const [numOfDays, setNumOfDays] = useState<number>(5);
  const formikRef = useRef<FormikProps<Holiday[]>>(null);
  const [holidays, setHolidays] = useState<Holiday[]>(
    JSON.parse(JSON.stringify(initialValues)),
  );
  const [originalHolidays, setOriginalHolidays] = useState<Holiday[]>(
    JSON.parse(JSON.stringify(initialValues)),
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [defaultMessage, setToastrDefaultMessage] = useState<string>();
  const [showCancelModel, setShowCancelModel] = useState<boolean>(false);
  const navigate = useNavigate();
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);

  useEffect(() => {
    toggleLoader(true);
    getHolidayList(formatDate(new Date(), 'MM/DD/YYYY'), programId)
      .then((holidayResponse) => {
        if (holidayResponse.holidayList.length > 0) {
          setNumOfDays(holidayResponse.holidayList.length);
          holidayResponse.holidayList.forEach((holiday) => {
            holiday.isPastDate = dayjs(holiday.holidayDate).isBefore(
              dayjs().startOf('date'),
            );
          });
          setHolidays(holidayResponse.holidayList);
          setOriginalHolidays(
            JSON.parse(JSON.stringify(holidayResponse.holidayList)),
          );
        } else {
          formikRef.current?.setValues(initialValues, true);
          setHolidays(initialValues);
          setOriginalHolidays(JSON.parse(JSON.stringify(initialValues)));
        }
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('holidayFetchFailureMessage');
          setToastrDefaultMessage('Failed to fetch Holidays');
        }
      });

    return () => {
      setHolidays([]);
      setOriginalHolidays([]);
    };
  }, []);

  useEffect(() => {
    if (!isEqual(holidays, originalHolidays)) {
      handleUpdateUnsavedChanges(true);
    } else {
      handleUpdateUnsavedChanges(false);
    }
  }, [holidays, originalHolidays]);

  const deleteExistingHoliday = (i: number, date: string) => {
    toggleLoader(true);
    deleteHoliday(date, programId)
      .then(() => {
        const filteredHolidays = holidays.filter((_, index) => index !== i);
        formikRef.current?.setValues(filteredHolidays);
        setHolidays(filteredHolidays);
        setOriginalHolidays(JSON.parse(JSON.stringify(filteredHolidays)));
        setNumOfDays(numOfDays - 1);
        setSuccessOrError('success');
        setToastrId('holidayDeleteSuccessMessage');
        setToastrDefaultMessage('Holiday deleted successfully');
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('holidayDeleteFailureMessage');
          setToastrDefaultMessage('Failed to delete Holiday');
        }
      });
  };

  const handleFormSubmission = async () => {
    const holidayCreateRequest: HolidayRequest = {
      holidayList: [],
      programId: programId,
    };
    const holidayUpdateRequest: HolidayRequest = {
      holidayList: [],
      programId: programId,
    };

    holidays.forEach((holiday) => {
      if (holiday.id === '') {
        holidayCreateRequest.holidayList.push(holiday);
      } else {
        if (!holiday.isPastDate) {
          holidayUpdateRequest.holidayList.push(holiday);
        }
      }
    });
    if (
      holidayCreateRequest.holidayList.length === 0 &&
      holidayUpdateRequest.holidayList.length === 0
    ) {
      return;
    }

    let holidaysCreated = false;
    let holidaysUpdated = false;

    toggleLoader(true);
    if (holidayCreateRequest.holidayList.length > 0) {
      const response = await createHolidayList(holidayCreateRequest, programId);
      if (response.ok) {
        holidays.forEach((holiday) => {
          if (response.data) {
            response.data.holidayList.forEach((item) => {
              if (item.holidayDate === holiday.holidayDate) {
                holiday.id = item.id;
              }
            });
          }
        });
        holidaysCreated = true;
      }
    } else {
      holidaysCreated = true;
    }
    if (holidayUpdateRequest.holidayList.length > 0) {
      const response = await updateHolidayList(holidayUpdateRequest);
      if (response.ok) {
        setOriginalHolidays(JSON.parse(JSON.stringify(holidays)));
        holidaysUpdated = true;
      }
    } else {
      holidaysUpdated = true;
    }

    setHolidays(holidays);
    setOriginalHolidays(JSON.parse(JSON.stringify(holidays)));
    toggleLoader(false);
    if (holidaysCreated && holidaysUpdated) {
      setSuccessOrError('success');
      setToastrId('holidaysSaveSuccessMessage');
    } else {
      setSuccessOrError('error');
      setToastrId('holidaySaveFailureMessage');
    }
  };
  return (
    <Box component="div">
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={defaultMessage}
          successOrError={successOrError}
        />
      )}
      {
        <ModalPopup
          description="formUnsavedChangesMessage"
          open={showCancelModel}
          onCancel={() => {
            setShowCancelModel(false);
          }}
          onOk={() => {
            setShowCancelModel(false);
            setSuccessOrError('success');
            setToastrId('formClearSuccessMessage');
            if (originalHolidays.length > 0) {
              setNumOfDays(originalHolidays.length);
              setHolidays(JSON.parse(JSON.stringify(originalHolidays)));
            }
          }}
          maxWidth={'xs'}
          labelId1="Clientpage.Nobtn"
          negativeActionLabel="No"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="Yes"
        />
      }
      <Box component="div">
        <Formik
          innerRef={formikRef}
          initialValues={holidays}
          validationSchema={validationSchema}
          validateOnMount
          validate={validateForm}
          enableReinitialize
          onSubmit={() => {
            handleFormSubmission();
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Box component="div">
              <Card className="formCardview">
                <Box className="flex__ mb-lg">
                  <MediumTypography
                    labelid={`${rctl.formatMessage({
                      id: 'holidaysText',
                      defaultMessage: 'Holidays',
                    })} :- `}
                    defaultlabel="Holidays"
                    className="mainText-xlg"
                  />
                  <MediumTypography
                    labelid={getFinancialYear()}
                    sxProps={{
                      color: '#00C6B8',
                    }}
                    className="mainText-xlg pl-xs"
                  />
                </Box>
                <Box component="div" className="p-lg">
                  <Grid container rowSpacing={3}>
                    {[...Array(numOfDays)].map((e, i) => (
                      <Grid
                        container
                        item
                        xs={12}
                        display={'flex'}
                        alignItems={'center'}
                        columnSpacing={3}
                        sx={{
                          pointerEvents: holidays[i].isPastDate
                            ? 'none'
                            : 'auto',
                        }}
                      >
                        <Grid container rowSpacing={3} columnSpacing={3}>
                          <Grid item xs={0.3}>
                            <MediumTypography
                              label={'0' + (i + 1)}
                              sxProps={{ color: '#00C6B8', paddingTop: '8px' }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <DatePickerComponent
                              labelid="dateTextHoliday"
                              required={true}
                              defaultlabelid="Date *"
                              value={dayjs(holidays[i].holidayDate)}
                              minDate={
                                holidays[i].isPastDate
                                  ? dayjs(holidays[i].holidayDate)
                                  : dayjs()
                              }
                              disabledDate={holidays[i].isPastDate}
                              maxDate={getFinancialYearJuneEndDate()}
                              handlechange={(date) => {
                                if (date === null) {
                                  holidays[i].holidayDate = null;
                                  setFieldValue('holidays[i].date', null, true);
                                  setHolidays((preValues) => {
                                    const newHolidays = [...preValues];
                                    newHolidays[i].holidayDate = null;
                                    return newHolidays;
                                  });
                                } else {
                                  holidays[i].holidayDate =
                                    dayjs(date).format('MM/DD/YYYY');
                                  setFieldValue(
                                    'holidays[i].date',
                                    dayjs(date).format('MM/DD/YYYY'),
                                    true,
                                  );
                                  setHolidays((preValues) => {
                                    const newHolidays = [...preValues];
                                    newHolidays[i].holidayDate =
                                      dayjs(date).format('MM/DD/YYYY');
                                    return newHolidays;
                                  });
                                }
                              }}
                            />
                            {errors &&
                              errors[i]?.holidayDate &&
                              touched &&
                              touched[i]?.holidayDate && (
                                <MediumTypography
                                  className="errorText-md"
                                  labelid={errors[i]?.holidayDate}
                                  defaultlabel="Field should not be empty"
                                />
                              )}
                          </Grid>
                          <Grid item xs={4}>
                            <Textinput
                              Value={holidays[i].title}
                              labelid="titleText"
                              Required={true}
                              defaultlabelid="Title"
                              inputProps={{
                                maxLength: 75,
                                readOnly: holidays[i].isPastDate,
                              }}
                              disabled={holidays[i].isPastDate}
                              handlechange={(value) => {
                                holidays[i].title = value;
                                setFieldValue('holidays[i].title', value, true);
                                setHolidays((preValues) => {
                                  const newHolidays = [...preValues];
                                  newHolidays[i].title = value;
                                  return newHolidays;
                                });
                              }}
                            />
                            {errors &&
                              errors[i]?.title &&
                              touched &&
                              touched[i]?.title && (
                                <MediumTypography
                                  className="errorText-md"
                                  labelid={errors[i]?.title}
                                  defaultlabel="Field should not be empty"
                                />
                              )}
                          </Grid>
                          {holidays.length > 1 && (
                            <Grid item sx={{ cursor: 'pointer' }}>
                              <Box
                                sx={{
                                  display: 'inline-flex',
                                  paddingTop: '6px',
                                }}
                                onClick={() => {
                                  const holiday = holidays[i];
                                  if (holiday.id === '') {
                                    const filteredHolidays = holidays.filter(
                                      (_, index) => index !== i,
                                    );
                                    formikRef.current?.setValues(
                                      filteredHolidays,
                                    );
                                    setHolidays(filteredHolidays);
                                    setNumOfDays(numOfDays - 1);
                                  } else {
                                    const date = holiday.holidayDate;
                                    if (date !== null) {
                                      deleteExistingHoliday(i, date);
                                    }
                                  }
                                }}
                              >
                                <DeleteAlertIcon />
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box component="div" sx={{ display: 'inline-flex' }}>
                  <MediumTypography
                    labelid="addHolidayText"
                    defaultlabel="+ Add Holiday"
                    onClick={() => {
                      const newHolidays = [...holidays];
                      newHolidays.push({
                        id: '',
                        holidayDate: null,
                        title: '',
                        isPastDate: false,
                      });
                      formikRef.current?.setValues(newHolidays, true);
                      setHolidays(newHolidays);
                      setNumOfDays(numOfDays + 1);
                    }}
                    sxProps={{
                      color: '#00C6B8',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      marginLeft: '36px',
                    }}
                  />
                </Box>
              </Card>

              <Box component="div">
                <Grid
                  className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                  container
                  direction="row"
                  alignItems="right"
                >
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-cancel btn_width_90px mr-md"
                      labelId="Contacts.cancelbtn"
                      defaultLabelId="Cancel"
                      variantType={'outlined'}
                      onClick={() => {
                        if (!isEqual(holidays, originalHolidays)) {
                          setShowCancelModel(!showCancelModel);
                        } else {
                          navigate(PROGRAM_MANAGEMENT_ROUTE_NAME);
                        }
                      }}
                    />

                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType={'contained'}
                      labelId="Insurance.save"
                      defaultLabelId="Save"
                      onClick={() => {
                        formikRef.current?.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default CreateHolidayList;
