import { ReactNode } from 'react';
import {
  ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME,
  ACTIVITY_SHEET_ROUTE_NAME,
  CASE_LOAD_LIST,
  CLIENTS_DASHBOARD_ROUTE_NAME,
  CODE_LIST,
  // EMAIL_TEXT_TEMPLATE,
  EMPLOYEES_ROUTE_NAME,
  EMPLOYEE_DASHBOARD_ROUTE_NAME,
  FORM_BUILDER_LIST_DISPLAY,
  // FORM_BUILDER_RESPONSE_LIST,
  INSURANCE_ROUTE_NAME,
  PROGRAM_MANAGEMENT_ROUTE_NAME,
  REFERRAL_DASHBOARD_ROUTE_NAME,
  RESOURCE_REPOSITORY_LIST,
  SCHEDULER_ROUTE_NAME,
  TRAVEL_EXPENSES,
  REVIEW_TRAVEL_EXPENSES,
  SSP_MASTER,
  TASK_MANAGER,
  USER_GROUPS,
  CLIENTS_ROUTE_NAME,
  CONSENT_DASHBOARD,
  QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
  INTAKE,
  IFSP_ROUTE_NAME,
  BATELLE_DEVELOPMENT_INVENTORY_2,
  FACESHEET,
  EICS_TBR_FORM,
  SSP_LIST,
  PROGRESS_NOTE_LIST_ROUTE_NAME,
  PROGRESS_NOTE_FACESHEET,
  LEA_REFERRAL,
  CANCELATION_REPORT,
} from '../routes/Routing';
import { checkPermissionForFeature } from '../utils/checkPermission';
import { OptionType } from '../utils/type';

export interface SidebarMenuItem {
  id: string;
  labelId: string;
  defaultLabel: string;
  displayId: string;
  to: string;
  type: 'NORMAL' | 'NESTED';
  leftIcon: string;
  rightIcon?: ReactNode;
  subMenus: SubMenu[];
}

export interface SubMenu {
  id: string;
  labelId: string;
  defaultLabel: string;
  displayId: string;
  to: string;
  leftIcon: string;
}

// labelId is used for Permission checks & displayId is used for displaying name
export const getSideBarMenus = (): SidebarMenuItem[] => {
  const selectedProgram = localStorage.getItem('selectedProgram') ?? '';
  const defaultProgramId = localStorage.getItem('defaultProgramId') ?? '';

  const menus: SidebarMenuItem[] = [
    {
      id: '0',
      labelId: 'noPermissionNeeded',
      defaultLabel: 'Dashboard',
      displayId: 'dashboardText',
      type: 'NORMAL',
      to: EMPLOYEE_DASHBOARD_ROUTE_NAME,
      leftIcon: 'dashboard',
      subMenus: [],
    },
    {
      id: '1',
      labelId: 'backend.clients',
      defaultLabel: 'Clients',
      displayId: 'backend.clients',
      type: 'NORMAL',
      to: CLIENTS_DASHBOARD_ROUTE_NAME,
      leftIcon: 'client',
      subMenus: [],
    },
    {
      id: '2',
      labelId: 'backend.referral_dashboard',
      defaultLabel: 'ReferralManagement',
      displayId: 'backend.referral_dashboard',
      type: 'NORMAL',
      to: REFERRAL_DASHBOARD_ROUTE_NAME,
      leftIcon: 'referral',
      subMenus: [],
    },
    {
      id: '3',
      labelId: 'backend.appointment',
      defaultLabel: 'Scheduler',
      displayId: 'backend.appointment',
      type: 'NORMAL',
      to: SCHEDULER_ROUTE_NAME,
      leftIcon: 'scheduler',
      subMenus: [],
    },
    {
      id: '4',
      labelId: 'backend.case_load',
      defaultLabel: 'Caseload List',
      displayId: 'backend.case_load',
      type: 'NORMAL',
      to: CASE_LOAD_LIST,
      leftIcon: 'caseload',
      subMenus: [],
    },
    {
      id: '5',
      labelId: 'noPermissionNeeded',
      defaultLabel: 'Progress Notes List',
      displayId: 'ProgressNoteList.myProgressNotestitle',
      type: 'NORMAL',
      to: PROGRESS_NOTE_LIST_ROUTE_NAME,
      leftIcon: 'progressNote',
      subMenus: [],
    },
    {
      id: '6',
      labelId: 'backend.task_manager',
      defaultLabel: 'Task Manager',
      displayId: 'backend.task_manager',
      type: 'NORMAL',
      to: TASK_MANAGER,
      leftIcon: 'taskmanager',
      subMenus: [],
    },
    {
      id: '7',
      labelId: 'backend.travel_expense',
      defaultLabel: 'Add Travel Expenses',
      displayId: 'backend.travel_expense',
      to: TRAVEL_EXPENSES,
      type: 'NORMAL',
      leftIcon: 'addtravelExpense',
      subMenus: [],
    },
    {
      id: '8',
      labelId: 'backend.review_travel_expense',
      defaultLabel: 'Review Travel Expense',
      displayId: 'backend.review_travel_expense',
      to: REVIEW_TRAVEL_EXPENSES,
      leftIcon: 'reviewtravelExpense',
      type: 'NORMAL',
      subMenus: [],
    },
    {
      id: '9',
      labelId: 'noPermissionNeeded',
      defaultLabel: 'My Activity',
      displayId: 'myActivityText',
      to: ACTIVITY_SHEET_ROUTE_NAME,
      leftIcon: 'addactivitysheet',
      type: 'NORMAL',
      subMenus: [],
    },
    {
      id: '10',
      labelId: 'backend.review_activity_sheet',
      defaultLabel: 'Review Activity',
      displayId: 'backend.review_activity_sheet',
      to: ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME,
      leftIcon: 'Reviewmyactivity',
      type: 'NORMAL',
      subMenus: [],
    },
    {
      id: '11',
      labelId: 'noPermissionNeeded',
      defaultLabel: 'Settings ',
      displayId: 'settingText',
      type: 'NESTED',
      to: 'preference',
      leftIcon: 'setup',
      subMenus: [
        {
          id: '1',
          labelId: 'backend.program_details',
          defaultLabel: 'Program List',
          displayId: 'backend.program_details',
          to: PROGRAM_MANAGEMENT_ROUTE_NAME,
          leftIcon: '',
        },
        {
          id: '2',
          labelId: 'backend.employee',
          defaultLabel: 'Employee List',
          displayId: 'backend.employee',
          to: EMPLOYEES_ROUTE_NAME,
          leftIcon: 'employeeList',
        },
        {
          id: '3',
          labelId: 'backend.form_builder',
          defaultLabel: 'Form Repository',
          displayId: 'backend.form_builder',
          to: FORM_BUILDER_LIST_DISPLAY,
          leftIcon: '',
        },
        {
          id: '4',
          labelId: 'backend.insurance_master',
          defaultLabel: 'Insurance',
          displayId: 'backend.insurance_master',
          to: INSURANCE_ROUTE_NAME,
          leftIcon: '',
        },
        {
          id: '5',
          labelId: 'backend.resource_repository',
          defaultLabel: 'Resource Repository',
          displayId: 'backend.resource_repository',
          to: RESOURCE_REPOSITORY_LIST,
          leftIcon: '',
        },
        // {
        //   id: '6',
        //   labelId: 'backend.email_text_template',
        //   defaultLabel: 'Email/Text Template',
        //   to: EMAIL_TEXT_TEMPLATE,
        //   leftIcon: '',
        // },
        {
          id: '7',
          labelId: 'backend.ssp_management',
          defaultLabel: 'SSP Management',
          displayId: 'backend.ssp_management',
          to: SSP_MASTER,
          leftIcon: '',
        },
        {
          id: '8',
          labelId: 'backend.code_list',
          defaultLabel: 'Code List',
          displayId: 'backend.code_list',
          to: CODE_LIST,
          leftIcon: '',
        },
        {
          id: '9',
          labelId: 'backend.groups',
          defaultLabel: 'Groups',
          displayId: 'backend.groups',
          to: USER_GROUPS,
          leftIcon: '',
        },
      ],
    },
    {
      id: '12',
      labelId: 'noPermissionNeeded',
      defaultLabel: 'Reports',
      displayId: 'reportsText',
      type: 'NESTED',
      to: 'preference',
      leftIcon: 'preference',
      subMenus: [
        {
          id: '1',
          labelId: 'backend.program_details',
          defaultLabel: 'Cancelation Report',
          displayId: 'cancelationReportTitle',
          to: CANCELATION_REPORT,
          leftIcon: '',
        },
      ],
    },
  ];
  return menus
    .filter((menu) => checkPermissionForFeature(menu.labelId, 'viewPermission'))
    .map((menu) => ({
      ...menu,
      subMenus: menu.subMenus.filter((subMenu) =>
        checkPermissionForFeature(subMenu.labelId, 'viewPermission'),
      ),
    }))
    .filter((menu) => {
      if (!defaultProgramId) return true;
      const isMenu =
        menu.to !== TRAVEL_EXPENSES && menu.to !== ACTIVITY_SHEET_ROUTE_NAME;
      return (
        (JSON.parse(selectedProgram) as OptionType).id === defaultProgramId ||
        isMenu
      );
    });
};

export const getFaceSheetSideBarManus = (): SidebarMenuItem[] => {
  return [
    {
      id: '0',
      labelId: 'Facesheet',
      defaultLabel: 'Facesheet',
      displayId: 'Facesheet',
      type: 'NORMAL',
      to: FACESHEET,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '1',
      labelId: 'Demographic.SidebarText',
      defaultLabel: 'Demographics',
      displayId: 'Demographic.SidebarText',
      type: 'NORMAL',
      to: CLIENTS_ROUTE_NAME,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '2',
      labelId: 'backend.progress_note',
      defaultLabel: 'Progress Notes List',
      displayId: 'ProgressNoteList.title',
      type: 'NORMAL',
      to: PROGRESS_NOTE_FACESHEET,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '3',
      labelId: 'Consents',
      defaultLabel: 'Consents',
      displayId: 'Consents',
      type: 'NORMAL',
      to: CONSENT_DASHBOARD,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '4',
      labelId: 'Intake',
      defaultLabel: 'Intake',
      displayId: 'Intake',
      type: 'NORMAL',
      to: INTAKE,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '5',
      labelId: 'BDI2.CreateNew',
      defaultLabel: 'Evaluation',
      displayId: 'BDI2.CreateNew',
      type: 'NORMAL',
      to: BATELLE_DEVELOPMENT_INVENTORY_2,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '6',
      labelId: 'asqSe2Text',
      defaultLabel: 'ASQ: SE-2',
      displayId: 'asqSe2Text',
      type: 'NORMAL',
      to: QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '7',
      labelId: 'IFSP',
      defaultLabel: 'IFSP',
      displayId: 'IFSP',
      type: 'NORMAL',
      to: IFSP_ROUTE_NAME,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '8',
      labelId: 'referralToSSPNotification',
      defaultLabel: 'SSP Notification',
      displayId: 'referralToSSPNotification',
      type: 'NORMAL',
      to: SSP_LIST,
      leftIcon: '',
      subMenus: [],
    },
    {
      id: '9',
      labelId: 'leaReferralTitleSidebar',
      defaultLabel: 'LEA Referral',
      displayId: 'leaReferralTitleSidebar',
      type: 'NORMAL',
      to: LEA_REFERRAL,
      leftIcon: '',
      rightIcon: 'dashboard',
      subMenus: [],
    },
    {
      id: '10',
      labelId: 'DischargeText',
      defaultLabel: 'Discharge',
      displayId: 'DischargeText',
      type: 'NORMAL',
      to: EICS_TBR_FORM,
      leftIcon: '',
      rightIcon: 'dashboard',
      subMenus: [],
    },
  ];
};

export const clientRoutes = [
  '/faceSheet',
  '/clients',
  '/client/progress-note',
  '/client/progress-note/setup',
  '/consent-dashboard',
  '/consent-dashboard/consent-form',
  '/asq-dashboard',
  '/asq-dashboard/asq-questionnaire',
  '/intake',
  '/ifsp',
  '/ssp-dashboard/referral-to-ssp',
  '/BDI-2',
  '/lea-referral',
  '/eics-tbr-Transition',
  '/asq-dashboard/asq-questionnaire/assessment',
  '/asq-dashboard/asq-questionnaire/summary',
  '/BDI-2/clinical-judgement-eligibility',
  '/BDI-2/eligibility-determination',
  '/BDI-2/risk-factor',
  '/eics-tbr',
  '/intake-review',
  '/ssp-dashboard',
];
