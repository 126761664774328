import { AlertColor, Box, Card, Grid } from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import RadioComponent from '../../components/formlib/RadioComponent';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import { ProgramConfigurationType } from '../../utils/type';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import SmallTypography from '../../components/formlib/SmallTypography';
import { editOrAddProgramConfiigurationApi } from '../../services/programManagment/programConfiguration';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { getProgramConfigurationApi } from './../../services/programManagment/programConfiguration';
import _ from 'lodash';
import { PROGRAM_MANAGEMENT_ROUTE_NAME } from '../../routes/Routing';
import { useNavigate } from 'react-router';
import ModalPopup from '../../components/formlib/ModalPopup';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

interface ProgramConfigProp {
  programId: string;
}

const initialValues: ProgramConfigurationType = {
  id: '',
  dueDay: '',
  mileageRate: '',
};

const validationSchema = yup.object().shape({
  dueDay: yup.string().nullable().required('Program.Configuration.dueDayError'),
  mileageRate: yup
    .string()
    .nullable()
    .required('Program.Configuration.MileageTextError'),
});

const ProgramConfiguration: FC<ProgramConfigProp> = ({ programId }) => {
  const formRef = useRef<FormikProps<ProgramConfigurationType>>(null);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [
    initialProgramConfigurationValue,
    setInitialProgramConfigurationValue,
  ] = useState<ProgramConfigurationType>(initialValues);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const navigate = useNavigate();
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  const selectedProgramTitle: string | null = selectedProgram
    ? JSON.parse(selectedProgram).label
    : '';
  const superProgramId: string | null = localStorage.getItem('superProgramId');
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleAddOrEdit = (values: ProgramConfigurationType) => {
    setInitialProgramConfigurationValue(values);
    editOrAddProgramConfiigurationApi(programId, values)
      .then(() => {
        setOpen(true);
        setToastrVariable('success');

        setToastrId('Program.Configuration.successmsg');
        setToastrDefaultMessage('Saved Successfully');
      })
      .catch((error) => {
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Program.Configuration.errormsg');
          setToastrDefaultMessage('Failed to Configure Program');
        }
      });
  };

  const getProgramConfiguration = () => {
    getProgramConfigurationApi(programId)
      .then((response) => {
        setInitialProgramConfigurationValue(response);
        formRef.current?.setValues(response);
      })
      .catch((error) => {
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Program.Configuration.errormsg');
          setToastrDefaultMessage('Failed to Configure Program');
        }
      });
  };

  useEffect(() => {
    getProgramConfiguration();
  }, []);

  const clearForm = () => {
    formRef.current?.setValues(initialProgramConfigurationValue);
    setOpenModal(false);
  };

  const handleBlur = (event: { target: { value: string } }) => {
    const newValue = event.target.value.trim();
    let formattedValue = newValue;
    if (newValue.endsWith('.')) {
      formattedValue = newValue + '0';
    }

    if (newValue.startsWith('.')) {
      formattedValue = '0' + newValue;
    }

    if (newValue === '.') {
      formattedValue = '0.0';
    }

    formRef.current?.setFieldValue('mileageRate', formattedValue);
  };

  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <ModalPopup
        description="formUnsavedChangesMessage"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onOk={() => clearForm()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
      <Formik
        innerRef={formRef}
        validateOnMount={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleAddOrEdit(values);
        }}
      >
        {({ setFieldValue, handleSubmit, values, errors, touched }) => {
          useEffect(() => {
            if (_.isEqual(initialProgramConfigurationValue, values)) {
              handleUpdateUnsavedChanges(false);
            } else {
              handleUpdateUnsavedChanges(true);
            }
          }, [values]);
          return (
            <Box>
              <Card className="formCardview">
                <TitleText
                  label={selectedProgramTitle}
                  Sxprops={{ fontSize: '20px' }}
                />
                <Box className="horizontalLine" />
                <Box>
                  <MediumTypography
                    labelid="program.Configuration.Accruals"
                    defaultlabel="Accruals"
                    fontSize="18px"
                    fontweight={600}
                  />
                  <Box
                    className="flex__ "
                    sx={{
                      paddingTop: '24px',
                    }}
                  >
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.Accruals.VacationAccrueRate"
                        defaultlabel="Vacation Accrue Rate"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        className=""
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="Enter"
                      />
                      <MediumTypography labelid="persent" defaultlabel="%" />
                    </Box>
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                        paddingLeft: '40px',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.Accruals.SickTimeAccrueRate"
                        defaultlabel="Sick Time Accrue Rate"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="Enter"
                      />
                      <MediumTypography labelid="Percent" defaultlabel="%" />
                    </Box>
                  </Box>
                </Box>
                <Box className="horizontalLine" />
                <Box>
                  <MediumTypography
                    labelid="Program.Configuration.CarryOvers"
                    defaultlabel="Carry overs"
                    fontSize="18px"
                    fontweight={600}
                  />
                  <Box
                    className="flex__ "
                    sx={{
                      paddingTop: '24px',
                    }}
                  >
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.CarryOvers.Vacationcarryoverallowed"
                        defaultlabel="Vacation carryover allowed"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="0"
                      />
                      <MediumTypography
                        labelid="Program.Configuration.Hours"
                        defaultlabel="hrs"
                      />
                    </Box>
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                        paddingLeft: '40px',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.CarryOvers.SickTimeCarryoverAllowed"
                        defaultlabel="Sick time carryover allowed"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="0"
                      />
                      <MediumTypography
                        labelid="Program.Configuration.Hours"
                        defaultlabel="hrs"
                      />
                    </Box>
                  </Box>
                  <Box
                    className="flex__ "
                    sx={{
                      paddingTop: '24px',
                    }}
                  >
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.CarryOvers.ProfessionalLeaveCarryoverAllowed"
                        defaultlabel="Professional leave carryover allowed"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="0"
                      />
                      <MediumTypography
                        labelid="Program.Configuration.Hours"
                        defaultlabel="hrs"
                      />
                    </Box>
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                        paddingLeft: '40px',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.CarryOvers.HolidayHoursCarryoverAllowed"
                        defaultlabel="Holiday hours carryover allowed"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="0"
                      />
                      <MediumTypography
                        labelid="Program.Configuration.Hours"
                        defaultlabel="hrs"
                      />
                    </Box>
                  </Box>
                  <Box
                    className="flex__ "
                    sx={{
                      paddingTop: '24px',
                    }}
                  >
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.CarryOvers.FlexHoursCarryoverAllowed"
                        defaultlabel="Flex hours carryover allowed"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="0"
                      />
                      <MediumTypography
                        labelid="Program.Configuration.Hours"
                        defaultlabel="hrs"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="horizontalLine" />
                <Box>
                  <MediumTypography
                    labelid="Program.Configuration.Add-Ons"
                    defaultlabel="Add-ons"
                    fontSize="18px"
                    fontweight={600}
                  />
                  <Box
                    className="flex__ "
                    sx={{
                      paddingTop: '24px',
                    }}
                  >
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.Add-Ons.Non-Direct"
                        defaultlabel="Non-Direct"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="0"
                      />
                    </Box>
                    <Box
                      className="flex__ "
                      sx={{
                        alignItems: 'center',
                        paddingLeft: '40px',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.Add-Ons.Non-Exempt"
                        defaultlabel="Non-Exempt"
                        sxProps={{ whiteSpace: 'nowrap' }}
                      />
                      <Textinput
                        sxProps={{ paddingLeft: '16px', paddingRight: '8px' }}
                        placeHolder="0"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="horizontalLine" />

                <Box>
                  <MediumTypography
                    labelid="Program.Configuration.WeeklyActivitySheet"
                    defaultlabel="Weekly activity sheet"
                    fontSize="18px"
                    fontweight={600}
                  />
                  <Box
                    sx={{
                      paddingTop: '24px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box
                      className="flex__"
                      sx={{
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.WeeklyActivitySheet.DueDate"
                        defaultlabel="Due Day"
                        sxProps={{ paddingRight: '16px', whiteSpace: 'nowrap' }}
                      />

                      <Box sx={{ width: '250px' }}>
                        <DropdownComponent
                          labelid="Program.Configuration.WeeklyActivitySheet.-Select-"
                          defaultlabelid="-select-"
                          names={[
                            { id: '0', label: 'Sunday' },
                            { id: '1', label: 'Monday' },
                            { id: '2', label: 'Tuesday' },
                            { id: '3', label: 'Wednesday' },
                            { id: '4', label: 'Thursday' },
                            { id: '5', label: 'Friday' },
                            { id: '6', label: 'Saturday' },
                          ]}
                          value={values.dueDay}
                          handleChange={(e) => {
                            setFieldValue('dueDay', e);
                          }}
                        />
                      </Box>
                    </Box>
                    {errors.dueDay && touched.dueDay && (
                      <SmallTypography
                        sxProps={{ color: 'red', marginLeft: '6%' }} // Adjust margin left for proper spacing
                        labelId={errors.dueDay}
                        defaultLabelId="Due Day is Required"
                      />
                    )}
                  </Box>
                </Box>
                <Box className="horizontalLine" />

                <Box>
                  <MediumTypography
                    labelid="Program.Configuration.TravelExpensesText"
                    defaultlabel="Add Travel Expenses"
                    fontSize="18px"
                    fontweight={600}
                  />
                  <Box
                    sx={{
                      paddingTop: '24px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box
                      className="flex__"
                      sx={{
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <MediumTypography
                        labelid="Program.Configuration.WeeklyActivitySheet.MileageText"
                        defaultlabel="Mileage Rate $"
                        sxProps={{ paddingRight: '16px', whiteSpace: 'nowrap' }}
                      />

                      <Box>
                        <Textinput
                          sxProps={{
                            paddingLeft: '0px',
                            width: '250px',
                          }}
                          Value={values.mileageRate}
                          handlechange={(text: string) => {
                            const newValue = text
                              .trimStart()
                              .replace(/[^\d.]/g, '')
                              .replace(/^(\d{0,2})(\.\d{0,4})?.*$/, '$1$2');
                            setFieldValue('mileageRate', newValue);
                          }}
                          placeHolder="Mileage Rate"
                          onblur={handleBlur}
                          disabled={
                            selectedProgram &&
                            JSON.parse(selectedProgram).id !== superProgramId
                              ? true
                              : false
                          }
                        />
                      </Box>
                    </Box>
                    {errors.mileageRate && touched.mileageRate && (
                      <SmallTypography
                        sxProps={{ color: 'red', marginLeft: '8%' }} // Adjust margin left for proper spacing
                        labelId={errors.mileageRate}
                        defaultLabelId="Mileage Rate is Required"
                      />
                    )}
                  </Box>
                </Box>
                <Box className="horizontalLine" />

                <Box>
                  <Box
                    className="flex__"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <MediumTypography
                      labelid="Program.Configuration.DefaultServiceVerificationMethod"
                      defaultlabel="Default service verification method"
                      fontSize="18px"
                      fontweight={600}
                      sxProps={{
                        paddingRight: '70px',
                        whiteSpace: 'nowrap',
                      }}
                    />
                    <RadioComponent
                      labelidA="Program.Configuration.RadioA"
                      defaultlabelidA="Phone"
                      labelidB="Program.Configuration.RadioB"
                      defaultlabelidB="Email"
                    />
                  </Box>
                </Box>
                <Box className="horizontalLine" />
                <Box>
                  <Box
                    className="flex__"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <MediumTypography
                      labelid="Program.Configuration.NumberOfClinicians"
                      defaultlabel="Service verification / Quality assurance: Number of clinicians to verify per month"
                      fontSize="18px"
                      fontweight={600}
                      sxProps={{
                        whiteSpace: 'nowrap',
                      }}
                    />
                    <Textinput
                      sxProps={{
                        paddingLeft: '40px',
                        width: '16%',
                      }}
                      placeHolder="Enter"
                    />
                  </Box>
                </Box>
                <Box className="horizontalLine" />
                <Box>
                  <Box
                    className="flex__"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <MediumTypography
                      labelid="Program.Configuration.NumberOfClients"
                      defaultlabel="Service verification / Quality assurance: Number of clients to verify per month"
                      fontSize="18px"
                      fontweight={600}
                      sxProps={{
                        whiteSpace: 'nowrap',
                      }}
                    />
                    <Textinput
                      sxProps={{
                        paddingLeft: '40px',
                        width: '16%',
                      }}
                      placeHolder="Enter"
                    />
                  </Box>
                </Box>
                <Box className="horizontalLine" />
                <Box>
                  <Box
                    className="flex__"
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <MediumTypography
                      labelid="Program.Configuration.ClientUniquenessTimeframe"
                      defaultlabel="Service verification / Quality assurance: Client uniqueness timeframe in number of month"
                      fontSize="18px"
                      fontweight={600}
                      sxProps={{
                        whiteSpace: 'nowrap',
                      }}
                    />
                    <Textinput
                      sxProps={{
                        paddingLeft: '40px',
                        width: '16%',
                      }}
                      placeHolder="Enter"
                    />
                  </Box>
                </Box>
              </Card>
              <Box>
                <Grid
                  container
                  direction="row"
                  alignItems="right"
                  className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                >
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="contained"
                      labelId="Contacts.cancelbtn"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (
                          _.isEqual(initialProgramConfigurationValue, values)
                        ) {
                          navigate(PROGRAM_MANAGEMENT_ROUTE_NAME);
                        } else {
                          setOpenModal(true);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-submit ml-md"
                      variantType="contained"
                      type="submit"
                      labelId="parentGuardian.save"
                      defaultLabelId="Save & Submit"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ProgramConfiguration;
