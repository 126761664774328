import React, { useCallback, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { OtherContactType } from '../../utils/type';
import * as yup from 'yup';
import { Box, Grid, AlertColor, Card } from '@mui/material';
import Textinput from '../../components/formlib/Textinput';
import MediumTypography from '../../components/formlib/MediumTypography';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  ContactsTypes,
  addContacts,
  getContactsBaedOnType,
  updateContacts,
} from '../../services/configApi/User';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import SmallTypography from '../../components/formlib/SmallTypography';
import ModalPopup from '../../components/formlib/ModalPopup';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import _ from 'lodash';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import {
  formatPhoneNumber,
  nameValidationWithNumbers,
} from '../../utils/formValidationUtil';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

const contactValues: OtherContactType = {
  contactType: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  startDate: dayjs(new Date()),
  endDate: null,
  isActive: true,
  organization: '',
  notes: '',
  contactId: '',
  isEdit: false,
  links: [''],
  companyOrMedicalPracticeName: '',
  isHouseHoldMember: false,
  fax: '',
};

const initialValidationSchema = yup.object().shape({
  contactType: yup.string(),
  firstName: yup.string().required('family.firstnamemessage'),
  lastName: yup.string().required('family.lastnameMessage'),
  phoneNumber: yup
    .string()
    .min(12, 'PhoneNumber.Validation')
    .nullable()
    .required('ContactOthers.phonenumberMandatory'),
  email: yup.string().email('SignIn.validation.inValidEmail'),
  notes: yup.string(),
  organization: yup.string(),
  startDate: yup.date().required('contact.startDateRequiredMessage').nullable(),
  endDate: yup.date().nullable(),
  isActive: yup.string().nullable(),
  contactId: yup.string(),
});

const validateForm = (value: OtherContactType) => {
  const errors: Partial<OtherContactType> = {};

  if (value.startDate) {
    if (dayjs(value.startDate).isAfter(dayjs())) {
      errors.startDate = 'validDate';
    }
  }

  if (value.endDate) {
    if (dayjs(value.endDate).isBefore(dayjs(value.startDate))) {
      errors.endDate = 'effectiveEndDateValidationMessage';
    }
  }

  if (
    datePickerMinDateMaxDateValidate(value.startDate) ||
    datePickerMinDateMaxDateValidate(value.endDate)
  ) {
    errors.startDate = 'datePickerMinDateMaxDateValidate';
    errors.endDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

const OthersContact = (props: {
  contacttype: string;
  onSuccess: (successerror: AlertColor, id: string, message: string) => void;
  otherValues: OtherContactType;
  editable: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [hideBtn, setHideBtn] = React.useState(false);
  const [otherVals, setOtherVals] =
    React.useState<OtherContactType>(contactValues);
  const [openModal, setOpenModal] = React.useState(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const rctl = useIntl();
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const { handleUpdateUnsavedChanges } = React.useContext(
    UnSavedChangesContext,
  );
  const formRef = React.useRef<FormikProps<OtherContactType>>(null);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!props.editable && props.otherValues.contactId !== '') {
      const clientId: string = localStorage.getItem('ClientId') ?? '';

      getContactsBaedOnType(clientId, props.otherValues.contactId)
        .then(async (response: ContactsTypes[]) => {
          if (response.length > 0) {
            const res = response[0] as OtherContactType;
            if (res.contactType === 'Others') {
              setOtherVals({
                contactId: res.contactId,
                firstName: res.firstName,
                lastName: res.lastName,
                phoneNumber: res.phoneNumber,
                email: res.email,
                startDate:
                  res.startDate === null ? dayjs(new Date()) : res.startDate,
                endDate: res.endDate,
                isActive: res.isActive,
                contactType: props.contacttype,
                isEdit: true,
                organization: res.organization,
                notes: res.notes,
                links: res.links,
                companyOrMedicalPracticeName: res.companyOrMedicalPracticeName,
                isHouseHoldMember: res.isHouseHoldMember,
                fax: res.fax,
              });
              setHideBtn(true);
            }
          }
        })
        .catch((error) => {
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Family.gettoastrError');
            setToastrDefaultMessage('Failed to get contact details');
          }
        });
    }
  }, [props.otherValues, props.editable]);

  const alphaNumericValidation = (value: string) => {
    let trimmedValue = value.replace(/^\s+/, '');
    const maxLength = 50;

    if (trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.slice(0, maxLength);
    }

    const regex = /^[A-Za-z.,'“!#_ -]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const companyValidation = (value: string) => {
    let trimmedValue = value.replace(/^\s+/, '');
    const maxLength = 100;

    if (trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.slice(0, maxLength);
    }

    const regex = /^[A-Za-z.,'“!()]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const clearForm = () => {
    setOpenModal(false);
    formRef.current?.resetForm();
    setOtherVals(contactValues);
  };

  const NumValidation = (value: string) => {
    const cleanValue = value.replace(/\D/g, '');
    const formattedValue = cleanValue.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      (match, p1, p2, p3) => {
        let formatted = p1;
        if (p2) formatted += '-' + p2;
        if (p3) formatted += '-' + p3;
        return formatted;
      },
    );
    return formattedValue.slice(0, 12);
  };

  const addOrUpdateOtherContact = useCallback(
    debounce((otherFormVals: OtherContactType) => {
      toggleLoader(true);
      otherFormVals.endDate =
        otherFormVals.endDate === null
          ? null
          : dayjs(otherFormVals.endDate).format('MM/DD/YYYY');

      otherFormVals.contactType = props.contacttype;
      otherFormVals.startDate =
        otherFormVals.startDate === null
          ? null
          : dayjs(otherFormVals.startDate).format('MM/DD/YYYY');
      if (otherFormVals.isEdit) {
        updateContacts(otherFormVals.contactId, otherFormVals)
          .then(async (response) => {
            if (response) {
              toggleLoader(false);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('Contact.updateMessage');
              setToastrDefaultMessage('Contact Updated Successfully');
              props.onSuccess(
                'success',
                'Contact.updateMessage',
                'Contact Updated Successfully',
              );
            }
          })

          .catch(async (error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              props.onSuccess(
                'error',
                'Contact.updateFailureMessage',
                'Failed to update contact details',
              );
              setToastrId('Contact.updateFailureMessage');
              setToastrDefaultMessage('Failed to update contact details');
            }
          });
      } else {
        const clientId: string = localStorage.getItem('ClientId') ?? '';

        addContacts(clientId, otherFormVals)
          .then(async (response) => {
            if (response) {
              toggleLoader(false);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('Contact.createdMessage');
              setToastrDefaultMessage(response.message);
              props.onSuccess(
                'success',
                'Contact.createdMessage',
                'Contact Added Successfully',
              );
            }
          })

          .catch(async (error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              props.onSuccess(
                'error',
                'Contact.updateCreateMessage',
                'Failed to create contact details',
              );
              setToastrId('Contact.updateCreateMessage');
              setToastrDefaultMessage('Failed to create contact details');
            }
          });
      }
    }, 1000),
    [],
  );

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Formik
        innerRef={formRef}
        initialValues={otherVals}
        enableReinitialize={true}
        validateOnChange={true}
        validationSchema={initialValidationSchema}
        validate={validateForm}
        onSubmit={(values) => {
          const finalValues = JSON.parse(JSON.stringify(values));
          if (finalValues.endDate) {
            const endDate = dayjs(finalValues.endDate, 'MM/DD/YYYY'); // Parse endDate
            const currentDate = dayjs(); // Current date

            if (endDate.isSame(currentDate, 'day')) {
              finalValues.isActive = true;
            } else if (endDate.isBefore(currentDate, 'day')) {
              finalValues.isActive = false;
            } else {
              finalValues.isActive = true; // Optional: For future dates
            }
          }
          addOrUpdateOtherContact(finalValues);
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          values,
          initialValues,
        }) => {
          useEffect(() => {
            if (_.isEqual(initialValues, values)) {
              handleUpdateUnsavedChanges(false);
            } else {
              handleUpdateUnsavedChanges(true);
            }
          }, [values]);
          return (
            <>
              <Card
                sx={{
                  padding: '10px 24px 24px 24px',
                  my: '0px',
                  ml: '0px',
                  height: '100%',
                  overflow: 'hidden',
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                  boxShadow:
                    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)',
                }}
              >
                <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`firstName`}
                      labelid="Family.firstName"
                      defaultlabelid="First Name"
                      handlechange={(value: string) => {
                        const inputValue = value;
                        const validatedValue =
                          alphaNumericValidation(inputValue);
                        if (validatedValue !== undefined) {
                          setFieldValue('firstName', validatedValue);
                        }
                      }}
                      Value={values.firstName}
                      Required={true}
                      inputProps={{
                        maxLength: 50,
                        pattern: '[A-Za-z .,!]+',
                      }}
                    />
                    {errors.firstName && touched.firstName && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.firstName}
                        defaultLabelId="First Name is required"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`lastName`}
                      labelid="Family.lastName"
                      defaultlabelid="Last Name"
                      handlechange={(value: string) => {
                        const inputValue = value;
                        const validatedValue =
                          alphaNumericValidation(inputValue);
                        if (validatedValue !== undefined) {
                          setFieldValue('lastName', validatedValue);
                        }
                      }}
                      Value={values.lastName}
                      Required={true}
                      inputProps={{
                        maxLength: 50,
                        pattern: '[A-Za-z .,!]+',
                      }}
                    />
                    {errors.lastName && touched.lastName && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.lastName}
                        defaultLabelId="Last Name is required"
                      />
                    )}
                  </Grid>

                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`phoneNumber`}
                      labelid="Family.phoneNumber"
                      defaultlabelid="Mobile Number"
                      handlechange={(value: string) => {
                        setFieldValue('phoneNumber', NumValidation(value));
                      }}
                      inputProps={{
                        pattren: ['0-9'],
                      }}
                      Required={true}
                      Value={values.phoneNumber}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.phoneNumber}
                        defaultLabelId="Phone Number is required"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`email`}
                      labelid="Family.email"
                      defaultlabelid="Email Address"
                      handlechange={(value: string) => {
                        setFieldValue('email', value);
                      }}
                      Value={values.email}
                      inputProps={{
                        maxLength: 320,
                      }}
                    />
                    {errors.email && touched.email && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.email}
                        defaultLabelId="Email is required"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name="referralContactFax"
                      Value={values.fax}
                      labelid="AddNewReferralDetails.Fax"
                      defaultlabelid="Fax"
                      Required={false}
                      inputProps={{
                        maxLength: 50,
                      }}
                      handlechange={(value: string) => {
                        setFieldValue('fax', formatPhoneNumber(value));
                      }}
                    />
                    {errors && errors.fax && touched && touched.fax && (
                      <MediumTypography
                        labelid={errors.fax}
                        defaultlabel="Invalid Fax Number"
                        className="errorText-md"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5}></Grid>
                  <Grid item xs={4.5}>
                    <CommentTextbox
                      maxLength={100}
                      rows={4}
                      name="organization"
                      placeholder={rctl.formatMessage({
                        id: 'organizationText',
                      })}
                      height="60px"
                      Value={values.organization}
                      handlechange={(value: string) => {
                        const inputValue = value;
                        const validatedValue = companyValidation(inputValue);
                        if (validatedValue !== undefined) {
                          setFieldValue('organization', validatedValue);
                        }
                      }}
                    />
                    {errors.organization && touched.organization && (
                      <MediumTypography
                        label={errors.organization}
                        textColor="red"
                        marginTop="8px"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5}>
                    <Textinput
                      name={`companyOrMedicalPracticeName`}
                      labelid="contacts.medicalOrFamilyPracticeName"
                      defaultlabelid="Company/Medical Practice Name"
                      handlechange={(value: string) => {
                        setFieldValue('companyOrMedicalPracticeName', value);
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      Value={values.companyOrMedicalPracticeName}
                    />
                  </Grid>

                  <Grid item xs={4.5}>
                    <DatePickerComponent
                      labelid="Family.startDate"
                      defaultlabelid="Start Date"
                      disableFuture={true}
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date === null) {
                          setFieldValue('startDate', null);
                        } else {
                          formRef.current?.setValues({
                            ...values,
                            startDate: formattedDate,
                            endDate: null,
                          });
                          // setFieldValue('startDate', formattedDate);
                          // setFieldValue('endDate', null);
                        }
                      }}
                      value={dayjs(values.startDate) as Dayjs}
                    />
                    {errors.startDate && touched.startDate && (
                      <MediumTypography
                        labelid={errors.startDate}
                        textColor="red"
                        marginTop="8px"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4.5}>
                    {values.endDate && (
                      <DatePickerComponent
                        labelid="Family.endDate"
                        defaultlabelid="End Date"
                        minDate={
                          values.startDate !== null
                            ? dayjs(values.startDate).add(1, 'day')
                            : undefined
                        }
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('endDate', null);
                          } else {
                            setFieldValue('endDate', formattedDate);
                          }
                        }}
                        value={dayjs(values.endDate) as Dayjs}
                      />
                    )}
                    {!values.endDate && (
                      <DatePickerComponent
                        labelid="Family.endDate"
                        defaultlabelid="End Date"
                        minDate={
                          values.startDate !== null
                            ? dayjs(values.startDate).add(1, 'day')
                            : undefined
                        }
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('endDate', null);
                          } else {
                            setFieldValue('endDate', formattedDate);
                          }
                        }}
                        value={null}
                      />
                    )}
                    {errors.endDate && touched.endDate && (
                      <MediumTypography
                        labelid={errors.endDate}
                        defaultlabel="End Date is required"
                        textColor="red"
                        marginTop="8px"
                      />
                    )}
                  </Grid>
                  <Grid
                    xs={4.5}
                    item
                    sx={{ marginTop: '-1%', marginBottom: '-1%' }}
                  >
                    <CheckBoxComponent
                      name="isHouseHoldMember"
                      labelid="Contacts.HouseHold"
                      defaultlabelid="Household Member"
                      fontWeight={400}
                      fontFamily="Lato-Regular"
                      ischecked={values.isHouseHoldMember}
                      CheckHandleChange={(e) => {
                        setFieldValue('isHouseHoldMember', e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid xs={4.5} item></Grid>
                  <Grid item xs={9} lg={9}>
                    <CommentTextbox
                      name="notes"
                      placeholder={rctl.formatMessage({
                        id: 'AddNewReferralDetails.notes',
                        defaultMessage: 'Notes',
                      })}
                      Value={values.notes}
                      maxLength={2000}
                      handlechange={(value: string) => {
                        const validatedValue = nameValidationWithNumbers(value);
                        if (validatedValue !== undefined) {
                          setFieldValue('notes', validatedValue);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Box sx={{ marginTop: '20px' }}>
                <Grid
                  container
                  direction="row"
                  alignItems="right"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid item sx={{ marginRight: '16px' }}>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="contained"
                      labelId="Contacts.cancelbtn"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (initialValues.contactId !== '') {
                          if (_.isEqual(initialValues, values)) {
                            formRef.current?.resetForm();
                            props.onSuccess('info', '', '');
                          } else {
                            setOpenModal(true);
                          }
                        } else {
                          if (!_.isEqual(initialValues, values)) {
                            setOpenModal(true);
                          } else {
                            setOpenModal(false);
                            formRef.current?.resetForm();
                            setOtherVals(contactValues);
                            props.onSuccess('info', '', '');
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item>
                    {!hideBtn && (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId="Contacts.save"
                        defaultLabelId="Add Contact"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    )}
                    {hideBtn && (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId="Contacts.update"
                        defaultLabelId="Update"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        }}
      </Formik>
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          if (otherVals.contactId !== '') {
            setOpenModal(false);
            formRef.current?.setValues(formRef.current?.initialValues);
          } else {
            clearForm();
          }
        }}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
    </>
  );
};

export default OthersContact;
