import { Grid, Card, Chip, Box } from '@mui/material';
import SelectComponent from '../../components/formlib/SelectComponent';
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChipData, OptionType, ResponseType } from '../../utils/type';
import {
  CLIENT_ID_KEY,
  CLIENT_NAME_KEY,
  STORAGE_USERNAME_KEY,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import MediumTypography from '../../components/formlib/MediumTypography';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { Formik, FormikProps } from 'formik';
import SmallTypography from '../../components/formlib/SmallTypography';
import * as yup from 'yup';
import { ReactComponent as CloseIcon } from '../../assets/images/x.svg';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { v4 as uuidv4 } from 'uuid';
import BrowseFiles from '../../components/formlib/BrowseFiles';
import {
  Tag,
  uploadFile,
} from '../../services/configApi/clientFiles/clientFilesProvider';
import { useIntl } from 'react-intl';
import { extractFileType } from '../../utils/fileUtils';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { formatFileTag } from '../../utils/formValidationUtil';
import { Context, ContextType } from '../../LanguageWrapper';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import _ from 'lodash';
import ModalPopup from '../../components/formlib/ModalPopup';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

interface FormProp {
  fileDescription: string;
  fileOrigin: string;
  fileType: string;
  note: string;
  files: number;
}

interface FileType {
  id: string;
  labelId: string;
  fileTypes: string;
}

const initialFormValues: FormProp = {
  fileDescription: '',
  fileOrigin: '',
  fileType: '',
  note: '',
  files: 0,
};

const fileTypes: FileType[] = [
  {
    id: '0',
    labelId: 'fileType1',
    fileTypes: '.pdf, .doc, .docx, .xls, .xlsx, .txt',
  },
  {
    id: '1',
    labelId: 'fileType2',
    fileTypes: '.jpg, .jpeg, .png, .heif',
  },
  { id: '2', labelId: 'fileType3', fileTypes: '.mp4, .wmv, .hevc' },
  { id: '3', labelId: 'otherText', fileTypes: '.' },
];

const fileOrigins: string[] = [
  'fileOrigins1',
  'fileOrigins2',
  'fileOrigins3',
  'otherText',
];

const validationScema = yup.object().shape({
  fileDescription: yup.string().required('ClientFiles.fileDescIsRequired'),
  fileOrigin: yup.string().required('ClientFiles.fileOriginIsRequired'),
  fileType: yup.string().required('ClientFiles.fileTypeIsRequired'),
  note: yup.string().required('ClientFiles.noteIsRequired'),
  files: yup.number().min(1, 'minFileListError').required('minFileListError'),
});

const AddClientFiles: FC<{
  updateReload: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ updateReload }) => {
  const [uploder, setUploader] = useState<OptionType | null>(null);
  const [selectedDocumentTypes, setSelectedDocumentTypes] =
    useState<string>('');
  const formikRef = useRef<FormikProps<FormProp>>(null);
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileFormat, setFileFormat] = useState<string>('');
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const context: ContextType = useContext(Context);
  const [openModal, setOpenModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);
  const rctl = useIntl();

  useEffect(() => {
    const userName = localStorage.getItem(STORAGE_USERNAME_KEY);
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userName && userId) {
      setUploader({ id: userId, label: userName });
    }
  }, []);

  useEffect(() => {
    formikRef.current?.setFieldValue('files', chipData.length);
  }, [chipData]);

  const onChipDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key),
    );
    setSelectedFiles((files) =>
      files.filter((file) => file.name !== chipToDelete.label),
    );
  };

  const handleCancelAction = () => {
    setOpenModal(false);
    formikRef.current?.resetForm();
    setChipData([]);
    setSelectedFiles([]);
    setSelectedDocumentTypes('');
    setFileFormat('');
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files !== null && files.length > 0) {
      const fileChips: ChipData[] = [...chipData];
      const fileList: File[] = [...selectedFiles];
      for (const file of files) {
        fileList.push(file);
        fileChips.push({
          key: uuidv4(),
          label: file.name,
          status: 'default',
        });
      }
      setSelectedFiles(fileList);
      setChipData(fileChips);
    }
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const handleSubmission = useCallback(
    debounce((values: FormProp) => {
      uploadFiles(values);
    }, 500),
    [uploder, selectedFiles, chipData],
  );

  const uploadFiles = async (values: FormProp) => {
    const clientId = localStorage.getItem(CLIENT_ID_KEY);
    const clientName = localStorage.getItem(CLIENT_NAME_KEY);
    if (clientId !== null && uploder !== null && clientName !== null) {
      toggleLoader(true);

      const failedFiles: ChipData[] = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const chipId = chipData[i].key;
        const tag: Tag = {
          clientId: clientId,
          origin: rctl.formatMessage({ id: values.fileOrigin }),
          description: values.fileDescription.trim(),
          userId: uploder.id,
          type: extractFileType(file.type),
          notes: values.note.trim(),
          program:
            context.selectedProgram !== undefined
              ? context.selectedProgram.id
              : '',
          timestamp: '',
          clientName: clientName.trim(),
        };

        try {
          await uploadFile(file, tag);
          setChipData((chips) =>
            chips.map((chip) => {
              if (chip.key === chipId) {
                return { ...chip, status: 'success' };
              }
              return chip;
            }),
          );
        } catch (error) {
          setChipData((chips) =>
            chips.map((chip) => {
              if (chip.key === chipId) {
                failedFiles.push(chip);
                return { ...chip, status: 'error' };
              }
              return chip;
            }),
          );
        }
      }

      if (failedFiles.length === 0) {
        setSelectedDocumentTypes('');
        setFileFormat('');
        toggleLoader(false);
        formikRef.current?.resetForm();
        setSelectedFiles([]);
        setChipData([]);
        setSuccessModal(true);
        updateReload((previousValue) => {
          return !previousValue;
        });
      } else {
        toggleLoader(false);
        setChipData(failedFiles);
        const remainingFiles = selectedFiles.filter((file) =>
          failedFiles.find((chip) => file.name === chip.label),
        );
        setSelectedFiles(remainingFiles);
        setToastrId('updateFailureMessage');
        setSuccessOrError('error');
        updateReload((previousValue) => {
          return !previousValue;
        });
      }
    }
  };

  return (
    <Box>
      <>
        {toastrId && (
          <SnackBarComponent
            open={toastrId !== undefined}
            handleClose={() => {
              setToastrId(undefined);
            }}
            labelId={toastrId}
            successOrError={successOrError}
          />
        )}

        {
          <ModalPopup
            open={openModal}
            description="formUnsavedChangesMessage"
            onCancel={() => setOpenModal(false)}
            onOk={handleCancelAction}
            labelId1="Clientpage.Nobtn"
            negativeActionLabel="No"
            labelId2="Clientpage.Yesbtn"
            positiveActionLabel="Yes"
            onClose={() => setOpenModal(false)}
          />
        }
        {
          <ModalPopup
            open={showSuccessModal}
            description="fileUploadSuccessMessage"
            onOk={() => setSuccessModal(false)}
            labelId2="IFSP.InfoOkButton"
            positiveActionLabel="Ok"
            onClose={() => setSuccessModal(false)}
          />
        }
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationScema}
          innerRef={formikRef}
          onSubmit={(values) => {
            handleSubmission(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            initialValues,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            useEffect(() => {
              if (_.isEqual(initialValues, values)) {
                handleUpdateUnsavedChanges(false);
              } else {
                handleUpdateUnsavedChanges(true);
              }
            }, [values]);
            return (
              <>
                <Card sx={{ padding: '16px' }}>
                  <Grid container direction={'column'} spacing={1}>
                    <Grid container item>
                      <Grid item xs={10}>
                        <CommentTextbox
                          maxLength={256}
                          rows={1}
                          Value={values.fileDescription}
                          placeholder={rctl.formatMessage({
                            id: 'AddClientFiles.fileDescriptionText',
                            defaultMessage: 'File Description',
                          })}
                          handlechange={(value) =>
                            setFieldValue(
                              'fileDescription',
                              formatFileTag(value),
                            )
                          }
                          Required={true}
                        />
                        {errors.fileDescription && touched.fileDescription && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.fileDescription}
                            defaultLabelId="File description is required"
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid container item spacing={2}>
                      <Grid item xs={5}>
                        <SelectComponent
                          names={fileOrigins}
                          labelid="AddClientFiles.fileOriginText"
                          defaultlabelid="AddClientFiles.fileOriginText"
                          value={values.fileOrigin}
                          handleChange={handleChange('fileOrigin')}
                          Required
                        />
                        {errors.fileOrigin && touched.fileOrigin && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.fileOrigin}
                            defaultLabelId="File Origin is required"
                          />
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        <SelectComponent
                          names={fileTypes.map((item) => item.labelId)}
                          labelid="AddClientFiles.fileTypeText"
                          defaultlabelid="AddClientFiles.fileTypeText"
                          value={values.fileType}
                          handleChange={(value) => {
                            setFieldValue('fileType', value);
                            const fileType = fileTypes.find(
                              (item) => item.labelId === value,
                            );
                            if (fileType) {
                              setFileFormat(fileType.fileTypes);
                              setSelectedDocumentTypes(fileType.fileTypes);
                            }
                            setSelectedFiles([]);
                            setChipData([]);
                          }}
                          Required
                        />
                        <MediumTypography
                          textColor="#F9B803"
                          label={selectedDocumentTypes}
                          marginTop="4px"
                        />
                        {errors.fileType && touched.fileType && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.fileType}
                            defaultLabelId="File Type is required"
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid container item spacing={2}>
                      <Grid item xs={10}>
                        <CommentTextbox
                          maxLength={256}
                          rows={2}
                          Value={values.note}
                          placeholder={rctl.formatMessage({
                            id: 'AddClients.enterNote',
                          })}
                          handlechange={(value) =>
                            setFieldValue('note', formatFileTag(value))
                          }
                          Required={true}
                        />
                        {errors.note && touched.note && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.note}
                            defaultLabelId="Note is required"
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid container item xs={10}>
                      <Grid item xs={2}>
                        <BrowseFiles
                          buttonLabelId="browseFilesText"
                          fileType="file"
                          accept={fileFormat}
                          handleFileChange={handleFileChange}
                        />
                        {errors.files && touched.files && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.files}
                            defaultLabelId="browseFile"
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid container item xs={10}>
                      {chipData.map((chip) => {
                        return (
                          <Grid item key={chip.key}>
                            <Chip
                              key={chip.key}
                              deleteIcon={<CloseIcon />}
                              label={chip.label}
                              color={chip.status}
                              onDelete={onChipDelete(chip)}
                              sx={{
                                borderRadius: '4px',
                                margin: '8px',
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Card>

                <Box sx={{ marginTop: '16px' }}>
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs={12}
                      alignItems="right"
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel"
                          variantType="contained"
                          labelId="cancelText"
                          defaultLabelId="cancelText"
                          onClick={() => {
                            if (!_.isEqual(initialValues, values)) {
                              setOpenModal(true);
                            } else {
                              history.back();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-submit ml-md"
                          variantType="contained"
                          labelId="uploadFilesText"
                          defaultLabelId="uploadFilesText"
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </>
            );
          }}
        </Formik>
      </>
    </Box>
  );
};

export default AddClientFiles;
