import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { UnSavedChangesContext } from '../context/UnSavedChangesProvider';

interface NavigationState {
  state?: object; // Adjust the type based on your actual structure
}

const useHandleNavigation = () => {
  const [openUnsavedModal, setOpenUnsavedModal] = useState<boolean>(false);
  const [pendingNavigation, setPendingNavigation] = useState<{
    to: string;
    state?: object;
  }>({
    to: '',
    state: {},
  });
  const navigate = useNavigate();
  const { handleUpdateUnsavedChanges, hasUnsavedChanges } = useContext(
    UnSavedChangesContext,
  );
  const handleNavigation = (to: string, state?: NavigationState) => {
    const extractedState = state?.state;

    if (hasUnsavedChanges) {
      localStorage.removeItem('consentDataJSON');
      localStorage.removeItem('selectedVersionNo');
      setPendingNavigation({ to, state: extractedState });
      setOpenUnsavedModal(true);
    } else {
      navigate(to, { state: extractedState });
    }
  };

  const confirmNavigation = () => {
    handleUpdateUnsavedChanges(false);
    navigate(pendingNavigation.to, { state: pendingNavigation.state });
    setOpenUnsavedModal(false);
  };

  const cancelNavigation = () => {
    setOpenUnsavedModal(false);
  };
  return {
    handleNavigation,
    openUnsavedModal,
    confirmNavigation,
    cancelNavigation,
  };
};

export default useHandleNavigation;
