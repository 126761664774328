import {
  Avatar,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Grid,
  ListItemText,
  AlertColor,
  Badge,
  MenuProps,
  Stack,
  CardContent,
} from '@mui/material';
import MediumTypography from '../components/formlib/MediumTypography';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { createContext, useContext, useEffect, useState } from 'react';
import CustomListButton from '../components/formlib/CustomListButton';
import { Context, ContextType } from '../LanguageWrapper';
import {
  ACTIVITY_APPROVAL_SHEET_ROUTE_NAME,
  ACTIVITY_SHEET_ROUTE_NAME,
  CLIENTS_ROUTE_NAME,
  EMPLOYEE_DASHBOARD_ROUTE_NAME,
  FACESHEET,
  IFSP_ROUTE_NAME,
  LEA_REFERRAL_CONSENT,
  NOTIFICATION_CENTER,
  PROFILE_ROUTE_NAME,
  QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
  SCHEDULER_ROUTE_NAME,
  TASK_MANAGER,
} from '../routes/Routing';
import { ReactComponent as Expandmoreprofile } from '../assets/images/expandmoresvg.svg';
import * as React from 'react';

import { styled, Theme, CSSObject } from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import Divider from '@mui/material/Divider';

import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ReactComponent as ThomLogoxs } from '../assets/images/logo/thomsmall.svg';
import { ReactComponent as ThomLogolg } from '../assets/images/ThomConnectLogoMainSidebar.svg';
import { ReactComponent as ThomLogolgSidebar2 } from '../assets/images/ThomConnectFaceSheetSidebarLogo.svg';
import { ReactComponent as Notification } from '../assets/images/notificationon.svg';
import {
  clientRoutes,
  getFaceSheetSideBarManus,
  getSideBarMenus,
} from './Sidebar';

import { ReactComponent as EmployeeIcon } from '../assets/images/employeeIcon.svg';
import { ReactComponent as Homesvg } from '../assets/images/Home.svg';
import { ReactComponent as DashboardIcon } from '../assets/images/dashboardIcon.svg';
import { ReactComponent as Settingssvg } from '../assets/images/settings.svg';
import { ReactComponent as Referraluser } from '../assets/images/Referaluser.svg';
import { ReactComponent as Calender } from '../assets/images/sidebarCalendar.svg';
import { ReactComponent as ProgramUser } from '../assets/images/list.svg';
import SidebarSubMenu from '../components/SidebarSubMenu';

import AutocompleteSearch from '../components/formlib/AutocompleteSearch';

import TitleText from '../components/formlib/TitleText';
import Loader from '../components/shared/Loader';
import { FaceSheetDemographicsType, OptionType } from '../utils/type';
import AppFooter from './AppFooter';
import { signOutUser } from '../services/userApi/User';
import { ApiError, isCustomError } from '../services/ApiResponseHandler';
import SnackBarComponent from '../components/formlib/SnackBarComponent';
import { ReactComponent as NotificationWithoutBell } from '../assets/images/notification_on.svg';
import { ReactComponent as ProgressNote } from '../assets/images/ProgressNoteMenuIcon.svg';
import { ReactComponent as CaseLoadIcon } from '../assets/images/caseloadlist.svg';
import { ReactComponent as TaskManager } from '../assets/images/taskmanager.svg';
import { ReactComponent as Addmyactivity } from '../assets/images/addmyactivity.svg';
import { ReactComponent as Reviewmyactivity } from '../assets/images/reviewmyactivity.svg';
import { ReactComponent as Addtravel } from '../assets/images/addtravelExpense.svg';
import { ReactComponent as Reviewtravel } from '../assets/images/reviewtravelExpense.svg';
import {
  getNotificationReadApi,
  getNotificationsApi,
  NotificationCenterType,
  NotificationMain,
} from '../services/configApi/notification/notification';
import {
  CLIENT_NAME_KEY,
  REFERRAL_ID_KEY,
  STORAGE_USER_ID_KEY,
} from '../services/Constant';
import { ReactComponent as NotifficationRead } from '../assets/images/notificationRead.svg';
import { getResponseDB } from '../services/storageDB';
import { formatDate } from '../utils/dateUtil';
import { LookUpContext } from '../context/LookUpContextProvider';
import { formatName } from '../utils/nameUtils';
import { convertGenderToFrontend } from '../utils/genderUtil';
import { checkPermissionForFeature } from '../utils/checkPermission';
import moment from 'moment';
import {
  getAllReferrals,
  getFacesheetDetails,
  ReferralResponseType,
} from '../services/configApi/Clients';
import { clearClientStorageDetails } from '../utils/utilities';
import ModalPopup from '../components/formlib/ModalPopup';
import useHandleNavigation from '../hooks/useHandleNavigation';

const drawerWidth = '15%';

const settings = ['Profile', 'Logout'];

interface HomeScreenPropType {
  onLogout: () => void;
  refreshToken: string | null;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const getMenuIcons = (type: string) => {
  switch (type) {
    case 'dashboard':
      return <DashboardIcon />;
    case 'client':
      return <Homesvg />;
    case 'referral':
      return <Referraluser />;
    case 'scheduler':
      return <Calender />;
    case 'progressNote':
      return <ProgressNote />;
    case 'employeeList':
      return <EmployeeIcon />;
    case 'programList':
      return <ProgramUser />;
    case 'settings':
      return <Settingssvg />;
    case 'caseload':
      return <CaseLoadIcon />;
    case 'taskmanager':
      return <TaskManager />;
    case 'addactivitysheet':
      return <Addmyactivity />;
    case 'Reviewmyactivity':
      return <Reviewmyactivity />;
    case 'addtravelExpense':
      return <Addtravel />;
    case 'reviewtravelExpense':
      return <Reviewtravel />;
    case 'reports':
      return <CaseLoadIcon />;
  }
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export interface LoaderContextType {
  loader: boolean;
  toggleLoader: (value: boolean) => void;
}

const CustomMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }: { theme: Theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(2),
    minWidth: 100,
    maxWidth: 400,
    maxHeight: 470,
    padding: '0px 0px',
    borderWidth: '0 10px 10px 10px',
    boxShadow: '10px 10px 50px 0px rgba(0, 71, 66, 0.3)',
  },
}));

export const LoaderContext = createContext<LoaderContextType | undefined>(
  undefined,
);

const AppSidebar: React.FC<HomeScreenPropType> = ({
  onLogout,
  refreshToken,
}) => {
  const {
    lookupsDemographics,
    isNotificationAndProfileEnabled,
    updateDemographics,
  } = useContext(LookUpContext);
  const {
    openUnsavedModal,
    cancelNavigation,
    confirmNavigation,
    handleNavigation,
  } = useHandleNavigation();
  const context: ContextType = useContext(Context);
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const [toastOpen, setToastOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [demographicsLookup, setDemographicsLookup] =
    useState<FaceSheetDemographicsType | null>(null);
  const [clientSidebar, setClientSidebar] = useState<boolean>(false);
  const [enrollmentNo, setEnrollmentNo] = useState<string | null>('');
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const fullNameTooltip =
    demographicsLookup !== null
      ? formatName(
          demographicsLookup.firstName,
          demographicsLookup.middleName,
          demographicsLookup.lastName,
          demographicsLookup.suffix,
        )
      : '-';

  const handleCustomNotificationEvent = () => {
    getNotifications();
  };

  useEffect(() => {
    window.addEventListener(
      'customNotificationEvent',
      handleCustomNotificationEvent,
    );

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        'customNotificationEvent',
        handleCustomNotificationEvent,
      );
    };
  }, []);

  useEffect(() => {
    setClientSidebar(clientRoutes.includes(window.location.pathname));
    setOpen(true);
  }, [clientRoutes.includes(window.location.pathname)]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (lookupsDemographics !== null)
      setDemographicsLookup(lookupsDemographics);

    const referralId = localStorage.getItem('referralId');
    const clientReferralDetails = JSON.parse(
      localStorage.getItem('clientReferralDetails') as unknown as string,
    ) as ReferralResponseType[];

    if (clientReferralDetails && clientReferralDetails.length > 0)
      clientReferralDetails.forEach((referral: ReferralResponseType) => {
        if (referral.referralId === referralId) {
          setEnrollmentNo(referral.dphId);
        }
      });
  }, [lookupsDemographics]);

  const [selectedProgram, setSelectedProgram] = useState<OptionType | null>(
    context.selectedProgram ? context.selectedProgram : null,
  );
  const MemorizedCustomMenu = React.useMemo(() => CustomMenu, []);
  const handlefocus = () => {
    if (document.visibilityState === 'visible') {
      getNotifications();
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', handlefocus);
    navigator.serviceWorker.addEventListener('message', () => {
      // Handle the received message (payload)
      setTimeout(() => {
        getNotifications();
      }, 1000);

      // Update state or perform other actions as needed
    });
    return () => {
      window.removeEventListener('visibilitychange', handlefocus);
    };
  }, []);

  const navigate = useNavigate();
  const user = localStorage.getItem('userName');
  const profileLogo = user?.charAt(0).toUpperCase();

  const [loader, setLoader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openit = Boolean(anchorEl);
  const [notificationList, setNotificationList] = useState<
    NotificationCenterType[]
  >([]);

  const [notificationUnreadCount, setNotificationUnreadCount] =
    useState<string>('');
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  const toggleLoader = (value: boolean) => {
    setLoader(value);
  };

  const contextValue = React.useMemo<LoaderContextType>(
    () => ({
      loader,
      toggleLoader,
    }),
    [loader, toggleLoader],
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleNotification = (event: React.MouseEvent<HTMLElement>) => {
    getNotifications();
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const getNotifications = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId === null) {
      return;
    }
    getNotificationsApi(userId, 0, 5, '', '', null)
      .then((response: NotificationMain) => {
        setNotificationList(response.notificationDetails);
        setNotificationUnreadCount(response.unreadCount);
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('SomethingWentWrongText');
          setToastrDefaultMessage('Something went wrong, Please try again!');
          setToastrVariable('error');
        }
      });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleNotificationResponse = (vals: NotificationCenterType) => {
    switch (vals.notificationType) {
      case 'intake_facilitator':
      case 'client':
        localStorage.setItem('ClientId', vals.notificationIdTwo);
        localStorage.setItem(CLIENT_NAME_KEY, vals.notificationIdThree);
        localStorage.setItem(REFERRAL_ID_KEY, vals.notificationId);
        if (checkPermissionForFeature('backend.clients', 'viewPermission')) {
          navigate(FACESHEET);
        }
        break;
      case 'insurance':
        if (
          checkPermissionForFeature('backend.clients', 'viewPermission') &&
          vals.referralId !== null
        ) {
          toggleLoader(true);
          localStorage.setItem('ClientId', vals.notificationIdTwo);
          localStorage.setItem(CLIENT_NAME_KEY, vals.notificationIdThree);
          localStorage.setItem(REFERRAL_ID_KEY, vals.referralId);
          localStorage.setItem('defaultReferralId', vals.referralId);
          getAllReferrals(vals.notificationIdTwo, vals.referralId)
            .then(async (response: ReferralResponseType[]) => {
              if (response) {
                localStorage.setItem(
                  'clientReferralDetails',
                  JSON.stringify(response),
                );
                getFacesheetDetails(vals.notificationIdTwo)
                  .then((responseData) => {
                    if (responseData) {
                      updateDemographics(responseData.demographics);
                    }
                    toggleLoader(false);
                    sessionStorage.setItem('tabName', '3');
                    navigate(CLIENTS_ROUTE_NAME, {
                      state: {
                        clientId: localStorage.getItem('ClientId'),
                        isCreate: true,
                      },
                    });
                  })
                  .catch((errorFacesheet) => {
                    toggleLoader(false);
                    setOpen(true);
                    setToastrVariable('error');
                    if (isCustomError(errorFacesheet)) {
                      const apiError = errorFacesheet as ApiError;
                      setToastrId(apiError.id);
                      setToastrDefaultMessage(apiError.message);
                    } else {
                      setToastrId('Client.error');
                      setToastrDefaultMessage(errorFacesheet);
                    }
                  });
              }
            })
            .catch((error) => {
              toggleLoader(false);
              setOpen(true);
              setToastrVariable('error');
              if (isCustomError(error)) {
                const apiError = error as ApiError;
                setToastrId(apiError.id);
                setToastrDefaultMessage(apiError.message);
              } else {
                setToastrId('clientLoadError');
                setToastrDefaultMessage('Failed to get client details');
              }
            });
        }
        break;
      case 'schedule':
        navigate(PROFILE_ROUTE_NAME);
        break;
      case 'appointment':
        if (
          checkPermissionForFeature('backend.appointment', 'viewPermission')
        ) {
          navigate(SCHEDULER_ROUTE_NAME, {
            replace: true,
            state: {
              date: formatDate(new Date(vals.notificationDate), 'YYYY-MM-DD'),
              appointmentId: vals.notificationId,
            },
          });
        }
        break;
      case 'appointment_deleted':
        if (
          checkPermissionForFeature('backend.appointment', 'viewPermission')
        ) {
          navigate(SCHEDULER_ROUTE_NAME, {
            replace: true,
            state: {
              date: formatDate(new Date(vals.notificationDate), 'YYYY-MM-DD'),
            },
          });
        }
        break;
      case 'activitysheet':
        navigate(ACTIVITY_SHEET_ROUTE_NAME);
        break;
      case 'activitysheetapproval':
        if (
          checkPermissionForFeature(
            'backend.review_activity_sheet',
            'viewPermission',
          )
        ) {
          navigate(ACTIVITY_APPROVAL_SHEET_ROUTE_NAME, {
            state: {
              id: vals.notificationId,
              date: vals.notificationDate,
              firstName: vals.notificationIdTwo,
              lastName: vals.notificationIdThree,
            },
          });
        }
        break;
      case 'task_manager':
        if (
          checkPermissionForFeature('backend.task_manager', 'viewPermission')
        ) {
          navigate(TASK_MANAGER);
        }
        break;
      default:
        return;
    }
  };

  const handleNotificationClick = (vals: NotificationCenterType) => {
    getNotifications();
    getNotificationReadApi(vals.id, true)
      .then(() => {
        handleNotificationClose();
        getNotifications();
        handleNotificationResponse(vals);
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('SomethingWentWrongText');
          setToastrDefaultMessage('Something went wrong, Please try again!');
          setToastrVariable('error');
        }
      });
  };
  const signout = () => {
    if (refreshToken === null) {
      return;
    }
    toggleLoader(true);
    signOutUser(refreshToken)
      .then(() => {
        toggleLoader(false);
        localStorage.clear();
        onLogout();
        navigate('/');
      })
      .catch(async (error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('SomethingWentWrongText');
          setToastrDefaultMessage('Something went wrong, Please try again!');
          setToastrVariable('error');
        }
      });
  };

  const displayNotificationIcon = () => {
    if (isNotificationAndProfileEnabled) {
      return notificationUnreadCount && notificationUnreadCount !== '0' ? (
        <Badge
          badgeContent={notificationUnreadCount}
          className="cursorPointer"
          onClick={handleNotification}
          color="error"
        >
          <Notification />
        </Badge>
      ) : (
        <Box className="cursorPointer" onClick={handleNotification}>
          <Notification />
        </Box>
      );
    } else {
      return <Box></Box>;
    }
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // const minHeightInPixels = vh * 100; //

    // Update state with the current window height
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    // Set viewport height and log window height on mount
    setViewportHeight();

    // Update viewport height and log window height on resize
    window.addEventListener('resize', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  return (
    <LoaderContext.Provider value={contextValue}>
      {
        <ModalPopup
          open={openUnsavedModal}
          description="UnsavedAlertDescriptionWhileLeaving"
          labelId1="UnSavedAlertCancel"
          labelId2="UnSavedAlertLeave"
          negativeActionLabel="No"
          positiveActionLabel="Yes"
          onCancel={() => {
            cancelNavigation();
          }}
          onOk={() => {
            confirmNavigation();
          }}
        />
      }
      {loader && <Loader />}
      <SnackBarComponent
        open={toastOpen}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{ background: '#fff', zIndex: 999 }}
          className="appHeaderStyle"
        >
          <Toolbar className="appToolbarPaddingZero">
            <Grid container ml={3} mr={3}>
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                className="headerRespoGrid"
                sx={{
                  alignContent: 'center',
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    // marginLeft: 28,
                    marginLeft: open === false ? '8%' : '28%', // Conditionally set display
                  }}
                ></Box>
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    style={{ color: '#2A4241' }}
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      // marginLeft: 6,
                      marginTop: '5px',
                      ...(open && { display: 'none' }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Box className="headerDropdown flex__ pl-sm ">
                  <TitleText
                    labelid="Program:"
                    defaultlabel="Program:"
                    Sxprops={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#97A6A5',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingRight: '5px',
                    }}
                  />

                  {context.programs.length === 1 ? (
                    <Box sx={{ alignContent: 'center' }}>
                      <MediumTypography
                        label={context.programs[0].label}
                        sxProps={{
                          fontSize: '14px',
                          fontWeight: '400',
                          color: '#00938e',
                          lineHeight: '16px',
                        }}
                      />
                    </Box>
                  ) : (
                    <AutocompleteSearch
                      sxProps={{
                        minWidth: '250px',
                        width: 'auto',
                        height: '48px',
                        padding: '6px 10px',
                      }}
                      disableClearable={true}
                      labelId="DashboardHeader.programDropDownLabel"
                      defaultId="Select Program"
                      optionHintId="DashboardHeader.programDropDownNoOptionsText"
                      data={context.programs}
                      disableLabel={false}
                      selectedValue={selectedProgram}
                      onSelectItem={(value: OptionType | null) => {
                        if (value !== null) {
                          setSelectedProgram(value);
                          context.updateSelectedProgram(value, true);
                          getNotifications();
                        }
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} md={6} xl={6} className="headerRespoGrid">
                <Box sx={{ flexGrow: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Box
                      className="headerDropdown"
                      sx={{
                        display: 'flex',

                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <Stack
                          spacing={4}
                          direction="row"
                          sx={{ color: 'action.active' }}
                        >
                          {displayNotificationIcon()}
                        </Stack>
                      </Box>
                      <MemorizedCustomMenu
                        id="demo-customized-menu"
                        anchorEl={anchorEl}
                        open={openit}
                        onClose={handleNotificationClose}
                        sx={{ width: '400px', padding: '0px' }}
                      >
                        <Box
                          sx={{
                            width: '400px',
                            padding: '18px 24px 16px 24px',
                          }}
                          component="div"
                          className="flex__justify__space-between pb-xs"
                        >
                          <MediumTypography
                            labelid={'notificationHeaderText'}
                            defaultlabel={'Notifications'}
                            fontSize="18px"
                            fontweight={600}
                            textColor="#2A4241"
                          />

                          <MediumTypography
                            onClick={() => {
                              handleNotificationClose();
                              navigate(NOTIFICATION_CENTER, {
                                state: {
                                  displayRead: false,
                                },
                              });
                            }}
                            className="pr-xs cursorPointer"
                            label={`${notificationUnreadCount} Unread Notifications`}
                            fontSize="14px"
                            fontweight={600}
                            textColor="#00C6B8"
                          />
                        </Box>
                        <Box
                          className="horizontalLine"
                          sx={{
                            marginBottom: '0px',
                            marginTop: '8px',
                            width: '100%',
                          }}
                        />
                        {notificationList.map((item, index) => {
                          return (
                            <Box
                              key={item.id}
                              onMouseEnter={() => setHoveredIndex(index)}
                              onMouseLeave={() => setHoveredIndex(-1)}
                            >
                              <Box
                                component="div"
                                className="flex__ cursorPointer"
                                sx={{
                                  padding: '16px 24px 16px 24px',
                                  backgroundColor:
                                    hoveredIndex === index
                                      ? '#ECF9F8'
                                      : 'transparent',
                                }}
                                onClick={() => handleNotificationClick(item)}
                              >
                                <Box>
                                  {item.notificationRead === false ? (
                                    <NotificationWithoutBell />
                                  ) : (
                                    <NotifficationRead />
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  {item.notificationRead === false ? (
                                    <MediumTypography
                                      label={item.body}
                                      fontSize="14px"
                                      fontweight={600}
                                      textColor="#2A4241"
                                      sxProps={{
                                        marginLeft: '16px',
                                        wordBreak: 'break-word',
                                      }}
                                    />
                                  ) : (
                                    <MediumTypography
                                      label={item.body}
                                      fontSize="14px"
                                      fontweight={500}
                                      textColor="#2A4241"
                                      sxProps={{
                                        marginLeft: '16px',
                                        wordBreak: 'break-word',
                                      }}
                                    />
                                  )}
                                  <MediumTypography
                                    label={moment(item.createdOn).format(
                                      'MM/DD/YYYY hh:mm:ss a',
                                    )}
                                    fontSize="14px"
                                    fontweight={500}
                                    textColor="#97A6A5"
                                    sxProps={{ marginLeft: '16px' }}
                                  />
                                </Box>
                              </Box>

                              <Box
                                className="horizontalLine"
                                sx={{ marginY: '0px', width: '100%' }}
                              />
                            </Box>
                          );
                        })}
                        {hoveredIndex <= 4 && (
                          <Box
                            className="cursorPointer"
                            sx={{
                              padding: '16px 0px 16px 0px',
                              textAlign: 'center',
                            }}
                          >
                            <MediumTypography
                              label={'View All'}
                              fontSize="14px"
                              fontweight={600}
                              textColor="#008C82"
                              onClick={() => {
                                handleNotificationClose();
                                navigate(NOTIFICATION_CENTER, {
                                  state: {
                                    displayRead: null,
                                  },
                                });
                              }}
                            />
                          </Box>
                        )}
                      </MemorizedCustomMenu>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                      <Tooltip
                        sx={{
                          backgroundColor: 'white',
                          color: 'white',
                          borderRadius: '4px',
                          fontSize: '14px',
                        }}
                        title={user}
                      >
                        <ListItemButton
                          className="pr-xs"
                          sx={{
                            justifyContent: 'flex-end',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                            paddingRight: '0px',
                          }}
                          onClick={handleOpenUserMenu}
                        >
                          <MediumTypography
                            className="pr-xs"
                            label={
                              user == null ? '' : `Hi ${user.split(' ')[0]}`
                            }
                            sxProps={{
                              color: '#2A4241',
                              fontWeight: 600,
                              maxWidth: '15ch',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          />
                          <Expandmoreprofile />
                        </ListItemButton>
                      </Tooltip>
                      <IconButton>
                        <Avatar
                          sx={{ width: '34px', height: '34px' }}
                        >{`${profileLogo}`}</Avatar>
                      </IconButton>
                    </Box>
                  </Box>

                  <Menu
                    sx={{ mt: '40px', width: '145px' }}
                    className="headerProfiledropdown"
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting, index) => (
                      <MenuItem
                        key={setting}
                        // disabled={
                        //   !(
                        //     isNotificationAndProfileEnabled ||
                        //     setting !== 'Profile'
                        //   )
                        // }
                        sx={{
                          '&:hover': {
                            color: 'var(--thom-green)',
                          },
                          borderBottom: '1px solid #E7E8ED',
                        }}
                        onClick={async () => {
                          handleCloseUserMenu();
                          if (index === 0) {
                            handleNavigation(PROFILE_ROUTE_NAME, {});
                          } else if (index === 1) {
                            const programIds = (await getResponseDB(
                              'programIds',
                            )) as string[];
                            if (programIds) {
                              if (programIds.length === 0) {
                                signout();
                              } else {
                                setToastOpen(true);
                                setToastrId('synclogout');
                                setToastrDefaultMessage(
                                  'Sync offline data to logout',
                                );
                                setToastrVariable('error');
                              }
                            } else {
                              signout();
                            }
                          }
                        }}
                      >
                        <MediumTypography
                          labelid={setting}
                          sxProps={{
                            fontSize: '12px',
                            fontWeight: '500',
                            textAlign: 'center',
                            padding: '4px 6px 4px 6px',
                          }}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          className={clientSidebar ? 'appSidebar2' : 'appSidebar'}
          variant="permanent"
          open={open}
          style={{
            zIndex: '999',
            background: '#163332',
            backgroundColor: '#163332',
          }}
        >
          <DrawerHeader
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              backgroundColor: '#163332 !importent',
              padding: '0px 0px 0px 8px',
            }}
          >
            {clientSidebar ? (
              <ThomLogolgSidebar2
                className={clientSidebar ? 'appSidebar2' : 'appSidebar'}
                style={{
                  ...(!open ? { display: 'none' } : { cursor: 'pointer' }),
                }}
                onClick={() => handleNavigation(EMPLOYEE_DASHBOARD_ROUTE_NAME)}
              />
            ) : (
              <Box>
                <ThomLogoxs
                  style={{
                    ...(open ? { display: 'none' } : { cursor: 'pointer' }),
                  }}
                  onClick={() =>
                    handleNavigation(EMPLOYEE_DASHBOARD_ROUTE_NAME)
                  }
                />
                <ThomLogolg
                  className="ThomconnectLogo"
                  style={{
                    ...(!open ? { display: 'none' } : { cursor: 'pointer' }),
                  }}
                  onClick={() =>
                    handleNavigation(EMPLOYEE_DASHBOARD_ROUTE_NAME)
                  }
                />
              </Box>
            )}

            <IconButton
              aria-label="Close drawer"
              onClick={handleDrawerClose}
              edge="start"
              sx={{
                color: '#fff',
                display: clientSidebar ? 'none' : '',
                visibility: clientSidebar ? 'hidden' : 'visible',
                marginLeft: 0,
                ...(!open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </DrawerHeader>

          {/* <Divider
            style={{
              borderColor: '#97A6A5',
              width: '86%',
              margin: '0 auto',
            }}
          /> */}
          {!clientSidebar ? (
            <List
              style={{
                backgroundColor: '#163332',
                color: '#fff',
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              {getSideBarMenus().map((item) => (
                <ListItem
                  onClick={() => {
                    if (item.type === 'NORMAL') {
                      handleNavigation(item.to);
                    }
                  }}
                  key={item.id}
                  disablePadding
                  sx={{
                    display: 'block',
                    padding: '0px 16px',
                    margin: '8px 0px',
                  }}
                  className="sidebarList"
                >
                  {item.type === 'NORMAL' && (
                    <ListItemButton
                      sx={{
                        minHeight: 32,
                        justifyContent: open ? 'initial' : 'center',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          display: open ? 'none' : 'block',
                          backgroundColor: pathname.includes(item.to)
                            ? '#008c82'
                            : 'none',
                          borderRadius: '4px',
                          width: '32px',
                          height: '32px',
                          padding: '7px',
                        }}
                      >
                        <Link
                          to={item.to}
                          className={
                            pathname.includes(item.to)
                              ? 'listItemIconColor'
                              : ''
                          }
                        >
                          {getMenuIcons(item.leftIcon)}
                        </Link>
                      </ListItemIcon>
                      <ListItemText
                        className={
                          clientSidebar ? 'textIconSidebar2' : 'textIconSidebar'
                        }
                        // className="textIconSidebar"
                        sx={{
                          opacity: open ? 1 : 0,
                          marginTop: '0px',
                          marginBottom: '0px',
                        }}
                      >
                        <CustomListButton
                          to={item.to}
                          onClick={() => {
                            sessionStorage.clear();
                            clearClientStorageDetails();
                          }}
                          leftIcon={getMenuIcons(item.leftIcon)}
                          labelId={item.displayId}
                          selected={pathname.includes(item.to)}
                        />
                      </ListItemText>
                    </ListItemButton>
                  )}

                  {item.type === 'NESTED' && item.subMenus.length > 0 && (
                    <Box onClick={handleDrawerOpen}>
                      <SidebarSubMenu
                        headerName={item.displayId}
                        defaultLabel={item.defaultLabel}
                        leftIcon={item.leftIcon}
                        subMenus={item.subMenus}
                        parentExpand={open}
                      />
                    </Box>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <Box>
              {demographicsLookup !== null && (
                <CardContent sx={{ textAlign: 'center', padding: '5px 24px' }}>
                  <Tooltip
                    placement="top"
                    title={fullNameTooltip.length > 10 ? fullNameTooltip : ''}
                    arrow
                    followCursor
                  >
                    <Box>
                      <MediumTypography
                        label={
                          demographicsLookup !== null
                            ? (() => {
                                const fullName = formatName(
                                  demographicsLookup.firstName,
                                  demographicsLookup.middleName,
                                  demographicsLookup.lastName,
                                  demographicsLookup.suffix,
                                );
                                return fullName.length > 22
                                  ? fullName.substring(0, 19) + '...'
                                  : fullName;
                              })()
                            : '-'
                        }
                        sxProps={{
                          fontSize: '18px',
                          fontWeight: '700',
                          color: '#2A4241',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          width: '100%',
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <Box justifyContent={'center'}>
                    <MediumTypography
                      label={
                        demographicsLookup.dateOfBirth && demographicsLookup.sex
                          ? `${
                              demographicsLookup.sex !== null
                                ? convertGenderToFrontend(
                                    demographicsLookup.sex,
                                  )
                                : ''
                            } | ${demographicsLookup.dateOfBirth}`
                          : ''
                      }
                      sxProps={{
                        color: '#2A4241',
                        fontSize: '12px',
                        fontWeight: '400',
                      }}
                    />
                  </Box>
                  <Tooltip
                    placement="top"
                    title={
                      enrollmentNo && enrollmentNo.length > 5
                        ? enrollmentNo
                        : ''
                    }
                    arrow
                    followCursor
                  >
                    <Box justifyContent={'center'}>
                      <MediumTypography
                        label={enrollmentNo ?? '-'}
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '12px',
                          fontWeight: '400',
                        }}
                      />
                    </Box>
                  </Tooltip>
                </CardContent>
              )}
              <List
                style={{
                  backgroundColor: '#CFDEDE',
                  color: '#fff',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
              >
                {getFaceSheetSideBarManus().map((item) => (
                  <ListItem
                    onClick={() => {
                      if (item.to === CLIENTS_ROUTE_NAME) {
                        sessionStorage.setItem('tabName', '0');
                        handleNavigation(item.to, {
                          state: {
                            clientId: localStorage.getItem('ClientId'),
                            isCreate: true,
                          },
                        });
                      } else if (
                        item.to === QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME
                      ) {
                        handleNavigation(
                          QUESTIONNAIRE_CLIENT_INFO_ROUTE_NAME,
                          {},
                        );
                      } else if (item.to === IFSP_ROUTE_NAME) {
                        handleNavigation(IFSP_ROUTE_NAME, {
                          state: {
                            ifspDueDate: demographicsLookup?.ifspDueDate,
                          },
                        });
                      } else if (item.to === LEA_REFERRAL_CONSENT) {
                        handleNavigation(LEA_REFERRAL_CONSENT, {
                          state: { consentId: '' },
                        });
                      } else {
                        handleNavigation(item.to, {});
                      }
                    }}
                    key={item.id}
                    disablePadding
                    sx={{
                      display: 'block',
                      padding: '0px 16px',
                      margin: '8px 0px',
                    }}
                    className="sidebarList"
                  >
                    {item.type === 'NORMAL' && (
                      <ListItemButton
                        sx={{
                          minHeight: 32,
                          justifyContent: open ? 'initial' : 'center',
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            display: open ? 'none' : 'block',
                            backgroundColor: pathname.includes(item.to)
                              ? '#008c82'
                              : 'none',
                            borderRadius: '4px',
                            width: '32px',
                            height: '32px',
                            padding: '7px',
                          }}
                        >
                          <Link
                            to={item.to}
                            className={
                              pathname.includes(item.to)
                                ? 'listItemIconColor'
                                : ''
                            }
                          >
                            {getMenuIcons(item.leftIcon)}
                          </Link>
                        </ListItemIcon>
                        <ListItemText
                          className={
                            clientSidebar
                              ? 'textIconSidebar2'
                              : 'textIconSidebar'
                          }
                          // className="textIconSidebar"
                          sx={{
                            opacity: open ? 1 : 0,
                            marginTop: '0px',
                            marginBottom: '0px',
                          }}
                        >
                          <CustomListButton
                            to={item.to}
                            leftIcon={getMenuIcons(item.leftIcon)}
                            labelId={item.displayId}
                            rightIcon
                            selected={pathname.includes(item.to)}
                          />
                        </ListItemText>
                        {/* <a>hi</a> */}
                      </ListItemButton>
                    )}
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Divider />
        </Drawer>

        <Box
          sx={{
            width: '100%',
            backgroundColor: '#e7f0f0',
          }}
        >
          <DrawerHeader className="DrawerHeaderDiv" />
          <Box
            sx={{
              minHeight: `calc(${windowHeight}px - 88px)`, // Dynamic min-height calculation
            }}
          >
            <Outlet />
          </Box>
          <AppFooter />
        </Box>
      </Box>
    </LoaderContext.Provider>
  );
};

export default AppSidebar;
